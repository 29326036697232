import { Box, Button, Collapse, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import PropTypes from "prop-types";
import { ArrowRight, ExpandLess, ExpandMore } from "@mui/icons-material";
import "../styles/NavItem.css";

export default function Navitem(props) {
  const {
    to,
    icon,
    title,
    menu,
    getIndexMenu,
    index,
    openCollapse,
    ...others
  } = props;

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div className="mt-3">
      <ListItem
        disableGutters
        sx={{
          display: "flex",
          mb: 0.5,
          py: 0,
          px: 2,
        }}
        {...others}
      >
        <Button
          open={openCollapse[index]}
          onClick={() => getIndexMenu(index)}
          startIcon={icon}
          disableRipple
          sx={{
            borderRadius: 1,
            color: "neutral.300",
            fontWeight: "fontWeightBold",
            justifyContent: "flex-start",
            px: 3,
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            "& .MuiButton-startIcon": {
              color: match ? "secondary.main" : "neutral.400",
            },
            "&:hover": {
              backgroundColor: "rgba(255,255,255, 0.08)",
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {openCollapse[index] ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </ListItem>
      <Collapse in={openCollapse[index]} timeout="auto" unmountOnExit>
        {menu?.Menu.map((item) => {
          return (
            <NavLink
              key={item?.id}
              to={item?.url}
              className={({ isActive }) =>
                isActive ? "text-decoration-none active" : "text-decoration-none inactive"
              }
            >
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  mb: 0.5,
                  py: 0,
                  px: 4,
                }}
              >
                <Button className="w-100" startIcon={<ArrowRight />}>
                  <ListItemText primary={item?.title} />
                </Button>
              </ListItem>
            </NavLink>
          );
        })}
      </Collapse>
    </div>
  );
}

Navitem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
};
