import { Breadcrumbs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import YullanmalarTable from "../../Components/Yullanmalar/YullanmalarTable";

export default function Yullanmalar() {
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Bosh sahifa
        </Link>
        <Typography>Yo'llanmalar</Typography>
      </Breadcrumbs>
      <YullanmalarTable />
    </div>
  );
}
