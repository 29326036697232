import { Add } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  // Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  FormControl,
  // FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuTable from "../../Components/Menu/MenuTable";
import { api } from "../../utils/api";

export default function Menu() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [menuGroups, setMenuGroups] = useState([]);
  const [menuGroupId, setMenuGroupId] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    api
      .get("menu_groups")
      .then((res) => {
        setMenuGroups(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    let body = {
      title,
      url,
      parent_id: menuGroupId,
    };
    console.log(body);

    api
      .post("menu/create", body)
      .then((res) => {
        console.log(res);
        handleClose();
        setTitle("");
        setUrl("");
        setMenuGroupId("");
      })
      .catch((err) => {
        console.log(err);
      })
        .finally(() => {
            window.location.reload();
        })
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Bosh sahifa
        </Link>
        <Typography>Menu</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" className="fw-bold">
          Menyular ro'yxati
        </Typography>
        <Button onClick={handleClickOpen} variant="contained">
          <Add />
          Menu qo'shish
        </Button>
      </Box>
      <MenuTable menuGroups={menuGroups} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Menu qo'shish</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nomi"
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            fullWidth
            size="small"
            variant="standard"
          />

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Url nomi"
            onChange={(e) => setUrl(e.target.value)}
            type="text"
            fullWidth
            size="small"
            variant="standard"
          />

          <FormControl margin="dense" variant="standard" fullWidth>
            <InputLabel size="small" id="demo-simple-diplom-label">
              Menu Grouplar
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-diplom-label"
              id="demo-simple-select"
              onChange={(e) => setMenuGroupId(e.target.value)}
              label={"Menu Grouplar"}
            >
              {menuGroups.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Bekor qilish
          </Button>
          <Button onClick={handleSubmit}>Qo'shish</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
