import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";

export default function Markaz() {
  const { month_id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Bosh sahifa
        </Link>
        <Link to="/nurse" style={{ textDecoration: "none", color: "black" }}>
          Hamshiralar
        </Link>
        <Typography>Markaz</Typography>
      </Breadcrumbs>
      <TableContainer sx={{ borderRadius: "16px", p: 2 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="fw-bold">#</TableCell>
              <TableCell className="fw-bold">Holati</TableCell>
              <TableCell className="fw-bold" align="right">
                Hodisa
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="fw-bold" width={"5px"}>
                1
              </TableCell>
              <TableCell> Xali ko`rib chiqilmagan xujjatlar</TableCell>
              <TableCell align="right">
                <Link to={`/send_informations/${month_id}/0/0`}>
                  <Button variant="contained">Ko'rish</Button>
                </Link>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="fw-bold" width={"5px"}>
                2
              </TableCell>
              <TableCell> Rad Etilgan xujjatlar</TableCell>
              <TableCell align="right">
                <Link to={`/send_informations/${month_id}/0/1`}>
                  <Button variant="contained">Ko'rish</Button>
                </Link>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="fw-bold" width={"5px"}>
                3
              </TableCell>
              <TableCell> Tasdiqlangan xujjatlar</TableCell>
              <TableCell align="right">
                <Link to={`/send_informations/${month_id}/0/2`}>
                  <Button variant="contained">Ko'rish</Button>
                </Link>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell className="fw-bold" width={"5px"}>
                4
              </TableCell>
              <TableCell> Qabul qilingan xujjatlar</TableCell>
              <TableCell align="right">
                <Link to={`/send_informations/${month_id}/0/3`}>
                  <Button variant="contained">Ko'rish</Button>
                </Link>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell className="fw-bold" width={"5px"}>
                5
              </TableCell>
              <TableCell> Takroran yuborilgan xujjatlar</TableCell>
              <TableCell align="right">
                <Link to={`/send_informations/${month_id}/0/4`}>
                  <Button variant="contained">Ko'rish</Button>
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
