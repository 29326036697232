import { Camera } from "@mui/icons-material";
import { Button, Card, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
// import CropImage from "./Crop";
// import ReactCrop from "react-image-crop";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";

export default function WebCam() {
  const webcamRef = useRef(null);
  const { id } = useParams()
  const [imageSrc, setImageSrc] = useState();
  const capture = React.useCallback(() => {
    setImageSrc(webcamRef.current.getScreenshot());
  }, [webcamRef]);
  const navigate = useNavigate()

  const SendPhoto = () => {
    let body = {
      image: imageSrc,
      id: id,
    };

    api.post('send_informations/info/screen_store', body)
    .then(() => {
      navigate(`/nurse/preview/${id}`)
    })
    .catch((err) => console.log(err))
  };

  return (
    <div className={imageSrc ? "" : "d-flex justify-content-center"}>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={imageSrc ? 6 : 12}>
            <Card sx={{ borderRadius: "16px", p: 3 }}>
              <Webcam
                audio={false}
                screenshotFormat="image/jpeg"
                ref={webcamRef}
                className="w-100"
                style={{ borderRadius: "16px" }}
              />
              <Box sx={{ textAlign: "center" }}>
                <Button variant="contained" onClick={capture}>
                  <Camera sx={{ mr: 2 }} /> Suratga olish
                </Button>
              </Box>
            </Card>
          </Grid>

          {imageSrc && (
            <Grid item xs={6}>
              <Card sx={{ borderRadius: "16px", p: 3 }}>
                <img alt='Rasm' src={imageSrc} />
              </Card>
              <Box className="text-end mt-2" onClick={SendPhoto}>
                <Button
                  variant="contained"
                  style={{ width: "20%" }}
                >
                  Yuborish
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}
