import { Breadcrumbs, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RolesContext } from "../../App";
import IshRejaTable from "../../Components/Markaz/IshRejaTable";
import NotAllowed from "../NotAllowed";

export default function IshReja() {

  const role = useContext(RolesContext);

  if (role?.role?.roles?.level === 2) {
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            Bosh sahifa
          </Link>
          <Typography>Markaz ish reja</Typography>
        </Breadcrumbs>
  
        <IshRejaTable />
      </div>
    );
  }else {
    return <NotAllowed />
  }


}
