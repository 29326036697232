import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { api } from "../utils/api";
import '../styles/pdf.css';
import { Print } from "@mui/icons-material";

export default function Pdf() {
  const [data, setData] = useState();
  const { id } = useParams();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  useEffect(() => {
    api
      .get(`send_informations/ticket/${id}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [id]);


 
  return (
    <>
      <div className="text-center">
        <Button onClick={() => handlePrint()} variant="contained" className="my-3" startIcon={<Print />} >Chop etish</Button>
      </div>
        <div className="wrapper" size="A4" style={{border: '2px solid black', padding: '50px 40px'}} ref={componentRef}>
          <center>
            O'zbekiston Respublikasi O'RTA TIBBIYOT VA DORISHUNOS
            XODIMLAR MALAKASINI OSHIRISH VA ULARNING IHTHISOSLASHTIRISH MARKAZI KON
            XUDUDIY BOLINMASI
          </center>
          <h2>
            <center>Bu hamshiraga ruxsat</center>
          </h2>
          <center>
            <p>
              Yo'nalish nomi:
              <b className="text-capitalize"> {data?.category?.title}</b>
            </p>

            <p>
              O'qish muddati
              <b className="text-capitalize">
                {" "}
                {data?.ticket?.start_date} dan {data?.ticket?.end_date} gacha
              </b>
            </p>
          </center>

          <p>
            Ismi:
            <b className="text-capitalize">
              {" "}
              {data?.nurse?.last_name} {data?.nurse?.first_name}{" "}
              {data?.nurse?.patrnomic}
            </b>
          </p>

          <p>
            Lavozimi
            <b className="text-capitalize">
              {" "}
              {data?.nurse?.nurse_document?.lavozim}
            </b>
          </p>

          <p>
            Yuborilayotgan tibbiyot muassassasining nomi:
            <b className="text-capitalize"> {data?.category?.title}</b>
          </p>

          <p>
            Yuborilayotgan muassasa raxbarining F.I.SH:
            <b className="text-capitalize">
              {" "}
              {data?.nurse?.organization?.users[0].last_name}{" "}
              {data?.nurse?.organization?.users[0].name}
            </b>
          </p>
          <br />
          <br />
          <p>
            Hujjatning topshirilish sanasi:
            <b className="text-capitalize">
              {" "}
              {data?.ticket?.receive_from_day} dan {data?.ticket?.receive_to_day}{" "}
              gacha
            </b>
          </p>

          <p>
            Yo'llanma raqami:
            <b className="text-capitalize">{data?.ticket?.id}</b>
          </p>
        </div>
    </>
  );
}


