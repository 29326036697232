import React, { useEffect } from "react";
import { Box, Button, Divider, Drawer, Typography } from "@mui/material";
import Navitem from "./nav-item";
import { api } from "../utils/api";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
// import { ChevronLeft } from "@mui/icons-material";

export default function DashboardSidebar({ role  }) {
  const [menus, setMenus] = React.useState([]);
  const [openCollapse, setOpenCollapse] = React.useState(false);

  useEffect(() => {
    api
      .get("getMenus")
      .then((res) => {
        setMenus(res.data.data);
        let newData = res.data.data.filter((item) => item);
        setOpenCollapse(newData.map((item) => false));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getIndexMenu = (index) => {
    let cloneData = [...openCollapse];
    cloneData[index] = !cloneData[index];
    setOpenCollapse(cloneData);
  };

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div className="mt-3">
          <div className="px-3 pb-3 d-flex justify-content-between">
            <Link to="/" className="text-decoration-none">
              <img src={Logo} style={{ width: "100px" }} alt="" />
            </Link>
          </div>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.04)",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                px: 3,
                py: "11px",
                borderRadius: 1,
              }}
            >
              <div>
                <Typography color="inherit" variant="subtitle1">
                  {role?.role?.roles?.name}
                </Typography>
                <Typography color="neutral.400" variant="body2">
                  Muassasa ID: {role?.id}
                </Typography>
              </div>
              <Divider
                sx={{
                  borderColor: "#2D3748",
                  my: 3,
                }}
              />
              <Divider sx={{ borderColor: "#2D3748" }} />
            </Box>
          </Box>
              
          <div className="px-3 mt-3">
            <Link to="/nurse/create" className="text-decoration-none">
              <Button variant="contained" className="text-white w-100">
                Hamshira qo'shish
              </Button>
            </Link>
          </div>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 3,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {menus?.map((menu, index) => {
            return menu === null ? null : (
              <Navitem
                getIndexMenu={getIndexMenu}
                openCollapse={openCollapse}
                menu={menu}
                key={index}
                to={menu.Menu.map((item) => item?.url)}
                index={index}
                title={menu.title}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );

  return (
    <div>
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    </div>
  );
}
