import { ArrowUpward, CheckBox, Delete, Edit, MoreVert } from "@mui/icons-material";
import { Avatar, Card, CardHeader, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";

export default function Messages() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Card style={{ height: "85vh", overflowY: "auto" }}>
        {/* Topbar */}
        <CardHeader
        sx={{position: "sticky", top: 1, backgroundColor: "white", zIndex: 1, p: 1}}
          avatar={
            <Avatar aria-label="recipe">
              R
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVert />
            </IconButton>
          }
          title="John Doe"
      />


        {/* All messages list */}
        <div className="msgs">

          <div>
            <div
              className={`msg sent`}
            >
              <IconButton onClick={handleClick}>
                <MoreVert />
              </IconButton> 
              <p className="msg_text m-0">Salom</p>
            </div>
          </div>

          <div>
            <div
              className={`msg received`}
            >
              <p className="msg_text">HI 👋</p>
            </div>
          </div>

          <div>
            <div
              className={`msg received`}
            >
              <p className="msg_text">What's up?</p>
            </div>
          </div>

          <div>
            <div
              className={`msg sent`}
            >
              <p className="msg_text">Bez gap</p>
            </div>
          </div>

          <div>
            <div
              className={`msg received`}
            >
              <p className="msg_text">What's up?</p>
            </div>
          </div>

          <div>
            <div
              className={`msg sent`}
            >
              <p className="msg_text">Bez gap</p>
            </div>
          </div>

          <div>
            <div
              className={`msg sent`}
            >
              <p className="msg_text">Bez gap</p>
            </div>
          </div>

          <div>
            <div
              className={`msg received`}
            >
              <p className="msg_text">What's up?</p>
            </div>
          </div>

          <div>
            <div
              className={`msg sent`}
            >
              <p className="msg_text">Bez gap</p>
            </div>
          </div>

        </div>

        {/* Input for send message */}
        <div className="sendMsg">
          <TextField label="Send a message" size="small"  fullWidth />
          <IconButton
            size="small"
            style={{ backgroundColor: "#5048e5" }}
            className="ms-3 text-white"
          >
            <ArrowUpward />
          </IconButton>
        </div>

      </Card>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}><Edit className="me-2" />Tahrirlash</MenuItem>
        <MenuItem onClick={handleClose} className="text-danger" ><Delete className="me-2" /> O'chirish</MenuItem>
      </Menu>
    </div>
  );
}
