import React from 'react'
import IxtisosGroupTable from '../../Components/Ixtisos-Group/IxtisosGroupTable'

export default function IxtisosGroup() {
  return (
    <div>
        <IxtisosGroupTable />
    </div>
  )
}
