import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import image from "../assets/notAllowed.png";

export default function NotAllowed() {
  return (
    <div>
      <Grid container spacing={4} sx={{ alignItems: "center" }}>
        <Grid item xs={6}>
          <img src={image} className="w-100" alt="" />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "center" }}>
          <Typography variant="h1" color={"error"} className="fw-bold">
            Ruxsat yo'q
          </Typography>
          <Typography variant="h2" className="fw-bold">
            Ushbu sahifaga kirish uchun sizga ruxsat yo'q.
          </Typography>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            <Button
              variant="contained"
              sx={{ mt: 4 }}
              size={"large"}
              color="error"
            >
              Bosh sahifa
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
