import { LoadingButton } from "@mui/lab";
import {
  Card,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";

export default function MalakaMarkazItem() {
  const [arr, setArr] = useState([]);
  const { month_id, category_id } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    api
      .get(`send_informations/nurse-chunk/get/nurse/${month_id}/${category_id}`)
      .then((res) => {
        console.log(res.data);
        setArr(res.data.arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [month_id, category_id]);

  const SendInfo = (event) => {
    event.preventDefault();
    let newData = [];
    arr.forEach((item) => {
      item.forEach((child) => {
        newData.push(child);
      });
    });
    let id = newData.map((item) => {
      return {
        id: item?.id,
        group_id: item.group?.id,
      };
    });
    api
      .post("send_informations/nurse-chunk/get/nurse/group_store", { id })
      .then((res) => {
        navigate(`/malaka-markaz/${month_id}`);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <form onSubmit={SendInfo}>
        {arr?.map((item, index) => {
          return (
            <div key={index}>
              <h5>{item[0]?.group?.name}</h5>
              <Divider />
              <Card key={index} sx={{ my: 2, borderRadius: "16px" }}>
                <input
                  type="hidden"
                  name={"group_id" + index}
                  value={item[0]?.group?.id}
                />
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    className="table-bordered"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width={"50px"}>#</TableCell>
                        <TableCell width={"500px"}>Hamshira F.I.SH</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.map((item) => {
                        return (
                          <>
                            <input
                              name={"id" + index}
                              type="hidden"
                              value={item?.id}
                            />

                            <TableRow
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {item?.id}
                              </TableCell>
                              <TableCell>
                                {item?.nurse?.last_name}{" "}
                                {item?.nurse?.first_name}{" "}
                                {item?.nurse?.patrnomic}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </div>
          );
        })}

        <div className="text-end mt-3">
          <LoadingButton
            variant="contained"
            type="submit"
            style={{ width: "20%" }}
          >
            Jo'natish
          </LoadingButton>
        </div>
      </form>
    </div>
  );
}
