import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Alert,
  CircularProgress,
  Grid,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { api } from "../../utils/api";

export default function TicketEdit({ id, getData, open, setOpen }) {
  const [openAlert, setOpenAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [data, setData] = useState();

  const [start_date, setStart_day] = useState(null),
    [dan_date, setDan_date] = useState(null),
    [gacha_date, setGacha_date] = useState(null),
    [end15, setEnd15] = useState(null),
    [end2, setEnd2] = useState(null),
    [end3, setEnd3] = useState(null),
    [end4, setEnd4] = useState(null);

  useEffect(() => {
    setStart_day(data?.start_date);
    setDan_date(data?.dan_date);
    setGacha_date(data?.gacha_date);
    setEnd15(data?.end15);
    setEnd2(data?.end2);
    setEnd3(data?.end3);
    setEnd4(data?.end4);
  }, [data]);

  useEffect(() => {
    api.get(`ticketPermission/getById/${id}`).then((res) => {
      setData(res.data.success);
    });
  }, [id]);

  const EditMenu = (event) => {
    event.preventDefault();
    let body = {
      start_date: moment(start_date).format("YYYY-MM-DD"),
      dan_date: moment(dan_date).format("YYYY-MM-DD"),
      gacha_date: moment(gacha_date).format("YYYY-MM-DD"),
      end15: end15 ? moment(end15).format("YYYY-MM-DD") : end15,
      end2: end2 ? moment(end2).format("YYYY-MM-DD") : end2,
      end3: end3 ? moment(end3).format("YYYY-MM-DD") : end3,
      end4: end4 ? moment(end4).format("YYYY-MM-DD") : end4,
    };

    console.log(body);

    api
      .put(`ticketPermission/update/${id}`, body)
      .then((res) => {
        getData();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!data) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent className={"p-5"}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={EditMenu}>
          <DialogTitle>Permission qo'shish</DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {/* start_date */}
                  <div className="my-2">
                    <DesktopDatePicker
                      label={"Boshlanish vaqti"}
                      inputFormat="dd/MM/yyyy"
                      value={start_date}
                      onChange={(date) => setStart_day(date)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          required
                          className="w-100"
                          {...params}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {/* dan_date */}
                  <div className="my-2">
                    <DesktopDatePicker
                      label={"Tugash vaqti"}
                      inputFormat="dd/MM/yyyy"
                      value={dan_date}
                      onChange={(date) => setDan_date(date)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          required
                          className="w-100"
                          {...params}
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>

              {/* gacha_date */}
              <div className="my-2">
                <DesktopDatePicker
                  label={"Gacha vaqti"}
                  inputFormat="dd/MM/yyyy"
                  value={gacha_date}
                  onChange={(date) => setGacha_date(date)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      required
                      className="w-100"
                      {...params}
                    />
                  )}
                />
              </div>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {/* end15 */}
                  <div className="my-2">
                    <DesktopDatePicker
                      label={"15-dan qoldiq"}
                      inputFormat="dd/MM/yyyy"
                      value={end15}
                      onChange={(date) => setEnd15(date)}
                      renderInput={(params) => (
                        <TextField size="small" className="w-100" {...params} />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {/* end2 */}
                  <div className="my-2">
                    <DesktopDatePicker
                      label={"2-dan qoldiq"}
                      inputFormat="dd/MM/yyyy"
                      value={end2}
                      onChange={(date) => setEnd2(date)}
                      renderInput={(params) => (
                        <TextField size="small" className="w-100" {...params} />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {/* end3 */}
                  <div className="my-2">
                    <DesktopDatePicker
                      label={"3-dan qoldiq"}
                      inputFormat="dd/MM/yyyy"
                      value={end3}
                      onChange={(date) => setEnd3(date)}
                      renderInput={(params) => (
                        <TextField size="small" className="w-100" {...params} />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {/* end4 */}
                  <div className="my-2">
                    <DesktopDatePicker
                      label={"4-dan qoldiq"}
                      inputFormat="dd/MM/yyyy"
                      value={end4}
                      onChange={(date) => setEnd4(date)}
                      renderInput={(params) => (
                        <TextField size="small" className="w-100" {...params} />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="error">
              Bekor qilish
            </Button>
            <Button type="submit">Qo'shish</Button>
          </DialogActions>
        </form>
      </Dialog>
      {openAlert.open ? (
        <Snackbar
          open={openAlert.open}
          autoHideDuration={3000}
          onClose={() => setOpenAlert({ ...openAlert, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
        </Snackbar>
      ) : null}
    </div>
  );
}
