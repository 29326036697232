import React from 'react'
import MalakaTable from '../../Components/Malaka-Markaz/MalakaTable'

export default function MalakaMarkaz() {
  return (
    <div>
        
        <MalakaTable />
    </div>
  )
}
