import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import NurseCatTable from "../../Components/Send_information/NurseCatTable";

export default function NurseCatagory() {

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Bosh sahifa
        </Link>
        <Link to="/nurse" style={{ textDecoration: "none", color: "black" }}>
          Hamshira
        </Link>

        <Typography>Nurse Catagory</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" className="fw-bold">
          Katagoriya
        </Typography>
      </Box>
      <NurseCatTable />
    </div>
  );
}
