
import { Box, Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import NurseTable from "../../Components/Nurse/NurseTable";

export default function Users() {
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Bosh sahifa
        </Link>
        <Typography>Hamshiralar</Typography>
      </Breadcrumbs>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" className="fw-bold">
          Hamshiralar
        </Typography>
       
      </Box>
      <NurseTable />
    </div>
  );
}
