import { Edit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Alert,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { api } from "../../utils/api";

export default function NewsEdit({ getData, id }) {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [data, setData] = useState()

  useEffect(() => {
    api.get(`news/${id}`).then((res) => {
      setData(res.data.data);
    });
  }, [id]);

  const AddNews = (event) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget);
    let body = {
      title: data.get("title"),
      content: data.get("content"),
    };
    api
      .post(`news/${id}/update`, body)
      .then((res) => {
        getData();
        setOpen(false);
        setOpenAlert({
          open: true,
          message: "Yangilik tahrirlandi",
          severity: "success",
        });
      })
      .catch((err) => {
        setOpenAlert({
          open: true,
          message: "Yangilik tahrirlashda xatolik",
          severity: "error",
        });
        console.log(err);
      });
  };

  return (
    <div>
      <IconButton onClick={() => setOpen(true)}>
        <Edit color="primary" />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box component="form" onSubmit={AddNews} sx={{ mt: 1 }}>
          <DialogTitle>Yangilik Qo'shish</DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              focused
              name="title"
              defaultValue={data?.title}
            />
            <TextField
              id="outlined-multiline-static"
              label="Content"
              multiline
              rows={5}
              fullWidth
              size="small"
              focused
              name="content"
              defaultValue={data?.content}
              style={{ marginTop: "15px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="error">
              Bekor qilish
            </Button>
            <Button type="submit">
              Tahrirlash
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openAlert.open}
        autoHideDuration={3000}
        onClose={() => setOpenAlert({ ...openAlert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
      </Snackbar>
    </div>
  );
}
