import React, { useContext, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { api } from "../../utils/api";
import {  useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import NotAllowed from "../NotAllowed";
import { RolesContext } from "../../App";

export default function IshrejaItem() {
  const [data, setData] = React.useState([]);
  const { id } = useParams();
  const [month, setMonth] = React.useState([]);
  const navigate = useNavigate();
  const [response, setResponse] = React.useState([]);

  const role = useContext(RolesContext);

  useEffect(() => {
    api
      .get(`work-plan/getByCategory/${id}`)
      .then((res) => {
        setData(res.data.success);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    api
      .get(`months`)
      .then((res) => {
        setMonth(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const SendInfo = () => {
    let body = {
      category_id: Number(id),
      response,
    };

  // console.log(body);
    api
      .post("work-plan/addAndUpdate", body)
      .then((res) => {
        navigate('/work/list')
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (role?.role?.roles?.level === 2) {
    return (
      <div style={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            className="table-bordered"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell width={"50px"}>#</TableCell>
                <TableCell width={"100%"}>Yo'nalish nomi</TableCell>
                {month.map((item, index) => {
                  return (
                    <TableCell key={index} width={"100px"}>
                      {item.title}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {data?.category?.id}
                </TableCell>
                <TableCell>{data?.category?.title}</TableCell>
                {month.map((item, index) => {
                  return (
                    <TableCell key={index}>
                      <TextField
                        onChange={(e) => {
                          setResponse([
                            ...response,
                            {
                              month_id: item.id,
                              response: Number(e.target.value),
                            },
                          ]);
                        }}
                        id={`${item.month_id}`}
                        size="small"
                        style={{ width: "50px" }}
                        multiline
                        defaultValue={
                          data?.orgWorkPlan?.find((data) => data.month_id === item.id)
                              ?.response
                        }
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
  
        <Box className="text-end mt-3">
          <LoadingButton
            onClick={SendInfo}
            variant="contained"
            style={{ width: "20%" }}
          >
            Saqlash
          </LoadingButton>
        </Box>
      </div>
    );
  }
  else {
    return <NotAllowed />
  }

}
