import { Person } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

export default function Category2() {
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  const [start_date, setStart_date] = React.useState(new Date());
  const [end_date, setEnd_date] = React.useState(new Date());

  const HandleSubmit = (e) => {
    e.preventDefault();
    let data = new FormData(e.currentTarget);
    let formData = {
      companyName: data.get("companyName"),
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
      oyi: data.get("oyi"),
    };

    api
      .post(`send_informations/ixtisoslevel/${id}/create`, formData)
      .then((res) => {
        navigate(
          `/ticket/${res.data.send_information_id}`
        );
        // console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box component={"form"} onSubmit={HandleSubmit}>
          <Card
            sx={{
              borderRadius: "16px",
              p: 2,
              width: "80%",
              margin: "0 auto",
            }}
            className="shadow-lg"
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                <Box>
                  <Person style={{ color: "#597897" }} fontSize="large" />
                </Box>
                <Typography
                  variant={"h6"}
                  className="fw-bold"
                  style={{ color: "#597897" }}
                >
                  Hamshira Ismi
                </Typography>
              </Box>
              <Divider />

              <Box sx={{ my: 3 }}>
                <TextField
                  id="outlined-basic"
                  label="Kompaniya nomi"
                  variant="outlined"
                  fullWidth
                  name="companyName"
                  size="small"
                />
              </Box>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <Box>
                    <DesktopDatePicker
                      label={
                        <span>
                          Boshlanish vaqti
                          <span className="text-danger">*</span>
                        </span>
                      }
                      inputFormat="MM/dd/yyyy"
                      value={start_date}
                      onChange={(date) => setStart_date(date)}
                      renderInput={(params) => (
                        <TextField size="small" className="w-100" {...params} />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <DesktopDatePicker
                      label={
                        <span>
                          Tugash vaqti
                          <span className="text-danger">*</span>
                        </span>
                      }
                      size="small"
                      inputFormat="MM/dd/yyyy"
                      value={end_date}
                      onChange={(date) => setEnd_date(date)}
                      renderInput={(params) => (
                        <TextField size="small" className="w-100" {...params} />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ my: 3 }}>
                <FormControl fullWidth required>
                  <InputLabel size="small" id="demo-simple-diplom-label">
                    Oy
                  </InputLabel>
                  <Select
                    size="small"
                    required
                    labelId="demo-simple-diplom-label"
                    id="demo-simple-select"
                    label={`Oy`}
                    name="oyi"
                  >
                    <MenuItem value={15}>1.5-oy</MenuItem>
                    <MenuItem value={2}>2-oy</MenuItem>
                    <MenuItem value={3}>3-oy</MenuItem>
                    <MenuItem value={4}>4-oy</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </CardContent>
          </Card>

          <Box sx={{ textAlign: "end", width: "80%", margin: "0 auto", mt: 2 }}>
            <Button type="submit" variant="contained" sx={{ width: "20%" }}>
              Keyingisi
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    </div>
  );
}
