import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import DetailsThumb from "../../Components/Preview/DetailThump";
import "./Preview.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useParams } from "react-router-dom";
import { api } from "../../utils/api";

function OldNursePrev() {
  const { id, boolean, nurse_id } = useParams();
  const [products, setProducts] = useState([]);
  const [index, setIndex] = useState(0);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [comment, setComment] = React.useState("");

  // input values
  const [sababKor, setSababKor] = React.useState(false);


  const handleSubmit = () => {
    api
      .post(`send_informations/changeStatus/${id}/1`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));

    api
      .post(`send_information/add_comment/${id}`, { comment: comment })
      .then((res) => {
        setOpenModal(false);
        window.location.reload();
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };


  let src = [];
  products?.send_document?.forEach((element) => {
    src.push("http://lara.imedic.uz" + element.url);
  });

  const myRef = React.useRef();

  const handleTab = (index) => {
    setIndex(index);
    const images = myRef.current.children;
    for (let i = 0; i < images.length; i++) {
      images[i].className = images[i].className.replace("active", "");
    }
    images[index].className = "active";
  };


  useEffect(() => {
    api
    .get(`send_informations/preview/${id}?old=${boolean}&id=${nurse_id}`)
    .then((res) => {
      setProducts(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [id, boolean, nurse_id]);

  return (
    <div>
      {products?.status === 0 ? (
        <Alert severity="warning">Xali ko'rib chiqilmagan </Alert>
      ) : products?.status === 1 ? (
        <Alert severity="error">Rad etilgan</Alert>
      ) : products?.status === 2 ? (
        <Alert severity="success">Tasdiqlangan</Alert>
      ) : products?.status === 3 ? (
        <Alert severity="info">Qabul qilingan</Alert>
      ) : products?.status === 4 ? (
        <Alert severity="warning">Takroran yuborilgan</Alert>
      ) : (
        ""
      )}

      <Card
        style={{
          marginTop: "15px",
          borderRadius: "16px",
          boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
        }}
      >
        <div className="details">
          <Grid container spacing={2}>
            <Grid item xs={7} sm={7}>
              <Box sx={{ display: "flex" }}>
                <div className="pt-2 px-2">
                  <Zoom>
                    <img
                      className="rounded"
                      style={{ width: "550px" }}
                      src={src[index]}
                      alt=""
                    />
                  </Zoom>
                </div>
                <DetailsThumb images={src} tab={handleTab} myRef={myRef} />
              </Box>
              {products?.status === 2 || products?.status === 3 ? (
                products?.nurse_image === null ? null : (
                  <img
                    src={"http://lara.imedic.uz" + products?.nurse_image.url}
                    style={{
                      width: "200px",
                      marginTop: "10px",
                      marginLeft: "20px",
                      borderRadius: "16px",
                    }}
                    alt={"Nurse"}
                  />
                )
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={5} sm={5}>
              <div>
                <div>
                  <h3>
                    {products?.nurse?.last_name} {products?.nurse?.first_name}{" "}
                    {products?.nurse?.patrnomic}
                  </h3>
                </div>

                {/* List details */}
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Muassasa:</span>
                  <span className="text-muted">
                    {products?.organization?.title}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Pinfl:</span>
                  <span className="text-muted">{products?.nurse?.pinfl}</span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Passport seriya va raqami:</span>
                  <span className="text-muted">
                    {products?.nurse?.nurse_document?.serial}{" "}
                    {products?.nurse?.nurse_document?.number}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Bola parvarishi ta'tili:</span>
                  <span className="text-muted">
                    {products?.nurse?.nurse_document?.bola_date}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Oxirgi martda malaka oshirgan sana:</span>
                  <span className="text-muted">
                    {products?.nurse?.nurse_document?.malaka_date}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>O'qishni tamomlagan sana:</span>
                  <span className="text-muted">
                    {products?.nurse?.nurse_document?.bitirgan_date}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Diplom bo'yicha mutahasisligi:</span>
                  <span className="text-muted">
                    {products?.nurse?.nurse_document?.mutaxasislik}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Tamomlagan ta'lim muassasasi:</span>
                  <span className="text-muted">
                    {products?.nurse?.nurse_document?.bitirgan}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Lavozimi:</span>
                  <span className="text-muted">
                    {products?.nurse?.nurse_document?.lavozim}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Diplom seriya raqami:</span>
                  <span className="text-muted">
                    {products?.nurse?.nurse_document?.diplom}
                  </span>
                </div>
                <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                  <span>Phone:</span>
                  <span className="text-muted">+{products?.nurse?.phone}</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Bekor qilish sababi </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            rows={5}
            margin="dense"
            id="name"
            label="Bekor qilish sababini yozin"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Bekor qilish</Button>
          <Button color="error" onClick={handleSubmit}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={sababKor} onClose={() => setSababKor(false)}>
        <DialogTitle>Bekor qilinganligini sababi </DialogTitle>
        <DialogContent>
          <Typography>{products?.comment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setSababKor(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OldNursePrev;
