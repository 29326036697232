import {
  Alert,
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import PropTypes from "prop-types";
import {
  BoxUpload,
  Container,
  ImagePreview,
} from "../../pages/UploadImage/Upload2";
import { Grid } from "@mui/material";
import FolderIcon from "../../assets/folder_icon_transparent.png";
import {Close, SendSharp} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import EditLavel2 from "../UploadImage/EditLevel2";
import EditLavel3 from "../UploadImage/EditLevel3";
import EditLavel4 from "../UploadImage/EditLevel4";
import EditLavel5 from "../UploadImage/EditLevel5";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditSendInfo() {
  const [month, setMonth] = useState([]);
  const [catagory, setCatagory] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMount, setIsmount] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [openModal, setOpenModal] = React.useState(false);

  const [month_id, setMonth_id] = useState();
  const [category_id, setCatagory_id] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleImageChange(e) {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );

      // console.log("filesArray: ", filesArray);

      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
  }

  const DeletePhoto = (photo) => {
    let index = selectedFiles.indexOf(photo);
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
  };

  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <Grid item key={photo} spacing={2}>
          <ImagePreview>
            <div onClick={() => DeletePhoto(photo)} className="close-icon">
              <Close color="error" />{" "}
            </div>
            <img
              id="uploaded-image"
              src={photo}
              draggable={false}
              alt="uploaded-img"
            />
          </ImagePreview>
        </Grid>
      );
    });
  };

  const getData = () => {
    setIsmount(true);
    api
      .get(`send_informations/edit/${id}`)
      .then((res) => {
        setCatagory(res.data.category);
        setMonth(res.data.month);
        setData(res.data.data);
        setCatagory_id(res.data.data.category_id);
        setMonth_id(res.data.data.month_id);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setIsmount(false);
      });
  };


  useEffect(() => {
    getData();
  }, [id]);

  const handleSubmit = () => {
    api
      .post(`send_informations/edit-category/${id}`, { category_id, month_id })
      .then(() => {
        getData();
      });
  };

  const handlePassport = () => {
    setLoading(true);
    let formData = new FormData();
    let images = document.getElementById("upload-input").files;
    console.log(images, "test");
    Object.values(images).map((img, i) => {
      formData.append("upload_imgs[]", img);
      return true;
    });
    formData.append("send_id", id);
    formData.append("type", "1");
    formData.append("level", "6");

    api.post(`send_informations/upload-image/${id}`, formData).then((res) => {
      console.log(res, "res");
      setLoading(false);
      getData();
    });
  };

  const DownDelete = (item) => {
    console.log(item.id);

    api
      .post(`send_information/photo_delete/${item.id}`)
      .then(() => {
        getData();
      })
      .catch((err) => console.log(err));
  };

  const ChangeStatus = () => {
    api
      .post(`send_informations/changeStatus/${id}/4`)
      .then((res) => {
        navigate(`/nurse/preview/${id}`);
      })
      .catch((err) => console.log(err));
  };

  if (isMount) {
    return <div>loading...</div>;
  } else {
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            Bosh sahifa
          </Link>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            Nurse Document
          </Link>
          <Typography>Tahrirlash</Typography>
        </Breadcrumbs>
        {data?.status === 0 ? (
          <Alert severity="warning">Xali ko`rib chiqilmagan</Alert>
        ) : data?.status === 1 ? (
          <Alert style={{width: "95%", margin: "10px auto"}} severity="error">
            <div>
              <Typography>Rad etilgan</Typography>
              <Button onClick={() => setOpenModal(true)}>
                Sababini Ko'rish
              </Button>
            </div>
          </Alert>
        ) : data?.status === 4 ? (
          <Alert severity="warning">Takroran yuborilgan</Alert>
        ) : (
          ""
        )}
        <Card
          sx={{ borderRadius: "16px", p: 5, width: "95%", margin: "0 auto" }}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Categoriya nomini o'zgartirish
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Categoriya nomini o'zgartirish"
                    size="small"
                    onChange={(e) => setCatagory_id(e.target.value)}
                    multiline
                    focused
                    defaultValue={data.category_id}
                  >
                    {catagory.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Sanani o'zgartirish
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Sanani o'zgartirish"
                    size="small"
                    onChange={(e) => setMonth_id(e.target.value)}
                    defaultValue={Number(data.month_id)}
                  >
                    {month.map((item) => {
                      return (
                        <MenuItem key={item.id} value={Number(item.id)}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box className="mt-3 text-end">
              <Button
                variant="contained"
                className="ms-2"
                onClick={handleSubmit}
              >
                Saqlash
              </Button>
            </Box>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Passport rasimi" {...a11yProps(0)} />
              <Tab label="Diplom rasimi" {...a11yProps(1)} />
              <Tab label="Mehnat daftarchasi rasimi" {...a11yProps(2)} />
              <Tab label="Sertifikat rasimi" {...a11yProps(3)} />
              <Tab label="Nikoh qog'ozining rasimi" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <Container>
                  <h2>Passport rasimini yuklang</h2>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <BoxUpload>
                        <div className="image-upload">
                          <>
                            <label htmlFor="upload-input">
                              <img
                                src={FolderIcon}
                                draggable={"false"}
                                alt="placeholder"
                                style={{ width: 100, height: 100 }}
                              />
                              <p style={{ color: "#444" }}>
                                Rasm yuklash uchun bosing!
                              </p>
                            </label>

                            <input
                              id="upload-input"
                              type="file"
                              multiple
                              accept=".jpg,.jpeg,.png,"
                              onChange={handleImageChange}
                            />
                          </>
                        </div>
                      </BoxUpload>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        style={{
                          overflow: "auto",
                          height: "50vh",
                          marginTop: "20px",
                        }}
                      >
                        <Grid container spacing={3}>
                          {renderPhotos(selectedFiles)}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
              <Grid item xs={2}>
                <h3>Yuklangan rasmlar</h3>
                <Box style={{ overflowY: "auto", height: "50vh" }}>
                  {data?.send_document?.map((item) => {
                    return (
                      <Box key={item.id}>
                        {item.type === 1 ? (
                          <ImagePreview>
                            <div
                              onClick={() => DownDelete(item)}
                              className="close-icon"
                            >
                              <Close color="error" />{" "}
                            </div>
                            <img
                              id="uploaded-image"
                              src={"http://lara.imedic.uz" + item.url}
                              draggable={false}
                              alt="uploaded-img"
                            />
                          </ImagePreview>
                        ) : (
                          ""
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>

            <LoadingButton
              style={{ width: "20%" }}
              sx={{ mt: 2 }}
              fullWidth
              loading={loading}
              loadingPosition="end"
              variant="contained"
              onClick={handlePassport}
            >
              Saqlash
            </LoadingButton>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EditLavel2 data={data} getData={getData} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EditLavel3 data={data} getData={getData} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EditLavel4 data={data} getData={getData} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <EditLavel5 data={data} getData={getData} />
          </TabPanel>
        </Card>
        {data?.status === 1 ? (
          <div className="text-end m-4">
            <Button
                endIcon={<SendSharp />}
              onClick={ChangeStatus}
              sx={{ mt: 3, width: "25%" }}
              variant="contained"
            >
              Jo'natish
            </Button>
          </div>
        ) : (
          ""
        )}

        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Bekor qilinganligini sababi </DialogTitle>
          <DialogContent>
            <Typography>{data?.comment}</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => setOpenModal(false)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
