import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import DetailsThumb from "../../Components/Preview/DetailThump";
import "./Preview.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import {
  Camera,
  Check,
  Close,
  Edit,
  MeetingRoom,
  Redo,
  Undo,
  Visibility,
} from "@mui/icons-material";
import InputMask from "react-input-mask";
import { RolesContext } from "../../App";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

function Preview() {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [index, setIndex] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [open, setOpen] = React.useState(true);
  const openMenu = Boolean(anchorEl);
  const [openModal, setOpenModal] = React.useState(false);
  const [comment, setComment] = React.useState("");

  // input toggles
  const [fioPencil, setFioPencil] = React.useState(false);
  const [passwordPencil, setPasswordPencil] = React.useState(false);
  const [diplomPencil, setDiplomPencil] = React.useState(false);

  // input values
  const [firstName, setFirstName] = React.useState(""),
    [lastName, setLastName] = React.useState(""),
    [patrnomic, setPatrnomic] = React.useState(""),
    [serial, setSerial] = React.useState(""),
    [number, setNumber] = React.useState(""),
    [diplom, setDiplom] = React.useState(""),
    [sababKor, setSababKor] = React.useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  React.useEffect(() => {
    setFirstName(products?.nurse?.first_name);
    setLastName(products?.nurse?.last_name);
    setPatrnomic(products?.nurse?.patrnomic);
    setNumber(products?.nurse?.nurse_document?.number);
    setSerial(products?.nurse?.nurse_document?.serial);
    setDiplom(products?.nurse?.nurse_document?.diplom);
  }, [products]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const role = useContext(RolesContext);

  const handleSubmit = () => {
    api
      .post(`send_informations/changeStatus/${id}/1`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));

    api
      .post(`send_information/add_comment/${id}`, { comment: comment })
      .then((res) => {
        setOpenModal(false);
        window.location.reload();
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleClickCheck = () => {
    api
      .post(`send_informations/changeStatus/${id}/2`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        window.location.reload();
      });
  };

  let src = [];
  products?.send_document?.forEach((element) => {
    src.push({
      original: "http://lara.imedic.uz" + element.url,
      thumbnail: "http://lara.imedic.uz" + element.url,
    });
  });

  const myRef = React.useRef();

  const handleTab = (index) => {
    setIndex(index);
    const images = myRef.current.children;
    for (let i = 0; i < images.length; i++) {
      images[i].className = images[i].className.replace("active", "");
    }
    images[index].className = "active";
  };

  const getData = (id) => {
    api
      .get(`send_informations/preview/${id}`)
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  const QabulQilish = () => {
    api
      .post(`send_informations/changeStatus/${id}/3`)
      .then((res) => {
        window.location.reload();
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const SaveAfterEdit = () => {
    let body = {
      first_name: firstName,
      last_name: lastName,
      patrnomic: patrnomic,
      serial: serial,
      number: Number(number),
      diplom: diplom,
    };

    api
      .put(`nurse/${products.nurse_id}/updatePreview`, body)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const TasdiqniBekorQil = () => {
    api
      .post(`send_informations/changeStatus/${id}/4`)
      .then((res) => {
        window.location.reload();
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {products?.status === 0 ? (
        <Alert severity="warning">Xali ko'rib chiqilmagan </Alert>
      ) : products?.status === 1 ? (
        <Alert severity="error">Rad etilgan</Alert>
      ) : products?.status === 2 ? (
        <Alert severity="success">Tasdiqlangan</Alert>
      ) : products?.status === 3 ? (
        <Alert severity="info">Qabul qilingan</Alert>
      ) : products?.status === 4 ? (
        <Alert severity="warning">Takroran yuborilgan</Alert>
      ) : (
        ""
      )}

      <Card
        style={{
          marginTop: "15px",
          borderRadius: "16px",
          boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
        }}
      >
        <div className="details">
          <Grid container spacing={2}>
            <Grid item xs={7} sm={7}>
              {/* <Box sx={{ display: "flex" }}>
                <div className="pt-2 px-2">
                  <Zoom>
                    <img
                      className="rounded"
                      style={{ width: "550px" }}
                      src={src[index]}
                      alt=""
                    />
                  </Zoom>
                </div>
                <DetailsThumb images={src} tab={handleTab} myRef={myRef} />
              </Box> */}
              <ImageGallery
                items={src}
                thumbnailPosition={"right"}
              />

              {products?.status === 2 || products?.status === 3 ? (
                products?.nurse_image === null ? null : (
                  <img
                    src={"http://lara.imedic.uz" + products?.nurse_image.url}
                    style={{
                      width: "200px",
                      marginTop: "10px",
                      marginLeft: "20px",
                      borderRadius: "16px",
                    }}
                    alt={"Nurse"}
                  />
                )
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={5} sm={5}>
              {/*{*/}
              {/*  role?.role?.roles?.level === 3 ? null : (*/}
              {/*      <div>*/}
              {/*        {products?.status === 0 || products?.status === 4 ? (*/}
              {/*            <Box className="text-end">*/}
              {/*              <Tooltip title="Account settings">*/}
              {/*                <IconButton*/}
              {/*                    onClick={handleClick}*/}
              {/*                    size="small"*/}
              {/*                    sx={{ml: 2}}*/}
              {/*                    aria-controls={open ? "account-menu" : undefined}*/}
              {/*                    aria-haspopup="true"*/}
              {/*                    aria-expanded={open ? "true" : undefined}*/}
              {/*                >*/}
              {/*                  <Button variant="outlined">Amallar</Button>*/}
              {/*                </IconButton>*/}
              {/*              </Tooltip>*/}
              {/*              <Menu*/}
              {/*                  anchorEl={anchorEl}*/}
              {/*                  id="account-menu"*/}
              {/*                  open={openMenu}*/}
              {/*                  onClose={handleClose}*/}
              {/*                  onClick={handleClose}*/}
              {/*                  PaperProps={{*/}
              {/*                    elevation: 0,*/}
              {/*                    sx: {*/}
              {/*                      overflow: "visible",*/}
              {/*                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",*/}
              {/*                      mt: 1.5,*/}
              {/*                      "& .MuiAvatar-root": {*/}
              {/*                        width: 32,*/}
              {/*                        height: 32,*/}
              {/*                        ml: -0.5,*/}
              {/*                        mr: 1,*/}
              {/*                      },*/}
              {/*                      "&:before": {*/}
              {/*                        content: '""',*/}
              {/*                        display: "block",*/}
              {/*                        position: "absolute",*/}
              {/*                        top: 0,*/}
              {/*                        right: 14,*/}
              {/*                        width: 10,*/}
              {/*                        height: 10,*/}
              {/*                        bgcolor: "background.paper",*/}
              {/*                        transform: "translateY(-50%) rotate(45deg)",*/}
              {/*                        zIndex: 0,*/}
              {/*                      },*/}
              {/*                    },*/}
              {/*                  }}*/}
              {/*                  transformOrigin={{horizontal: "right", vertical: "top"}}*/}
              {/*                  anchorOrigin={{horizontal: "right", vertical: "bottom"}}*/}
              {/*              >*/}
              {/*                <MenuItem*/}
              {/*                    onClick={handleClickCheck}*/}
              {/*                    style={{color: "green"}}*/}
              {/*                >*/}
              {/*                  <ListItemIcon>*/}
              {/*                    <Check style={{color: "green"}} fontSize="small"/>*/}
              {/*                  </ListItemIcon>*/}
              {/*                  Tasdiqlash*/}
              {/*                </MenuItem>*/}
              {/*                <MenuItem*/}
              {/*                    onClick={handleClickOpen}*/}
              {/*                    style={{color: "red"}}*/}
              {/*                >*/}
              {/*                  <ListItemIcon>*/}
              {/*                    <Close style={{color: "red"}} fontSize="small"/>*/}
              {/*                  </ListItemIcon>*/}
              {/*                  Bekor qilish*/}
              {/*                </MenuItem>*/}
              {/*              </Menu>*/}
              {/*            </Box>*/}
              {/*        ) : (*/}
              {/*            ""*/}
              {/*        )}*/}
              {/*      </div>*/}
              {/*  )*/}
              {/*}*/}

              {role?.role?.roles?.level === 3 ||
              role?.role?.roles?.level === 1 ? null : (
                <Box className="text-end">
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      // aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      // aria-expanded={open ? "true" : undefined}
                    >
                      <Button variant="outlined">Amallar</Button>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {products?.status === 0 || products?.status === 4 ? (
                      <>
                        <MenuItem
                          onClick={handleClickCheck}
                          style={{ color: "green" }}
                        >
                          <Check style={{ color: "green" }} fontSize="small" />
                          Tasdiqlash
                        </MenuItem>
                        <MenuItem
                          onClick={handleClickOpen}
                          style={{ color: "red" }}
                        >
                          <Close style={{ color: "red" }} fontSize="small" />
                          Bekor qilish
                        </MenuItem>

                        {role?.role?.roles?.level === 3 ? (
                          <MenuItem>
                            <Link
                              to={`/nurse/document/${id}`}
                              className={
                                "text-decoration-none text-primary d-flex align-items-center"
                              }
                            >
                              <Edit className={"text-primary"} />
                              Tahrirlash
                            </Link>
                          </MenuItem>
                        ) : null}
                      </>
                    ) : products?.status === 1 ? (
                      <>
                        <MenuItem
                          onClick={() => setSababKor(true)}
                          className={"text-danger"}
                        >
                          <Close style={{ color: "red" }} fontSize="small" />
                          Sababini Ko'rish
                        </MenuItem>
                        {role?.role?.roles?.level === 3 ? (
                          <MenuItem>
                            <Link
                              to={`/nurse/document/${id}`}
                              className={
                                "text-decoration-none text-primary d-flex align-items-center"
                              }
                            >
                              <Edit className={"text-primary"} />
                              Tahrirlash
                            </Link>
                          </MenuItem>
                        ) : null}
                      </>
                    ) : products?.status === 2 ? (
                      <>
                        <MenuItem>
                          <Link
                            to={`/ticket/${id}`}
                            className={
                              "text-decoration-none text-primary d-flex"
                            }
                          >
                            <Visibility className={"text-primary"} />
                            PDF ko'rish
                          </Link>
                        </MenuItem>

                        {role?.role?.roles?.level === 3 ? null : (
                          <MenuItem onClick={QabulQilish}>
                            <MeetingRoom />
                            Qabul qilish
                          </MenuItem>
                        )}
                        {products?.nurse_image === null ? (
                          <MenuItem>
                            <Link
                              className="text-decoration-none text-dark d-flex"
                              to={`/web-cam/${id}`}
                            >
                              <Camera className="me-2" />
                              Rasmga olish
                            </Link>
                          </MenuItem>
                        ) : null}
                        <MenuItem
                          onClick={handleClickOpen}
                          style={{ color: "red" }}
                        >
                          <Close style={{ color: "red" }} />
                          Bekor qilish
                        </MenuItem>
                      </>
                    ) : products?.status === 3 ? (
                      <>
                        <MenuItem>
                          <Link
                            to={`/ticket/${id}`}
                            className={
                              "text-decoration-none text-primary d-flex"
                            }
                          >
                            <Visibility className={"text-primary"} />
                            PDF ko'rish
                          </Link>
                        </MenuItem>
                        <MenuItem
                          onClick={TasdiqniBekorQil}
                          className={"text-decoration-none text-danger d-flex"}
                        >
                          <Close className={"text-danger"} />
                          Tasdiqni bekor qilish
                        </MenuItem>
                      </>
                    ) : (
                      ""
                    )}
                  </Menu>
                </Box>
              )}

              {role?.role?.roles?.level === 3 && products?.status === 1 ? (
                <Box className="text-end">
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      // aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      // aria-expanded={open ? "true" : undefined}
                    >
                      <Button variant="outlined">Amallar</Button>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <>
                      <MenuItem
                        onClick={() => setSababKor(true)}
                        className={"text-danger"}
                      >
                        <Close style={{ color: "red" }} fontSize="small" />
                        Sababini Ko'rish
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to={`/nurse/document/${id}`}
                          className={
                            "text-decoration-none text-primary d-flex align-items-center"
                          }
                        >
                          <Edit className={"text-primary"} />
                          Tahrirlash
                        </Link>
                      </MenuItem>
                    </>
                  </Menu>
                </Box>
              ) : null}

              {role?.role?.roles?.level === 3 && products?.status === 2 ? (
                <Box className="text-end">
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      // aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      // aria-expanded={open ? "true" : undefined}
                    >
                      <Button variant="outlined">Amallar</Button>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem>
                      <Link
                        to={`/ticket/${id}`}
                        className={"text-decoration-none text-primary d-flex"}
                      >
                        <Visibility className={"text-primary"} />
                        PDF ko'rish
                      </Link>
                    </MenuItem>
                  </Menu>
                </Box>
              ) : null}

              {role?.role?.roles?.level === 2 ? (
                <div>
                  {products?.status === 0 ? (
                    <div>
                      <div>
                        {fioPencil ? (
                          <>
                            <div className="d-flex g-1 justify-content-between px-3 py-2">
                              <TextField
                                id="outlined-basic"
                                label="Familya"
                                size="small"
                                variant="outlined"
                                style={{ width: "100%" }}
                                defaultValue={products?.nurse?.last_name}
                                focused
                                onChange={(e) => setLastName(e.target.value)}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Ism"
                                size="small"
                                variant="outlined"
                                style={{ width: "100%" }}
                                defaultValue={products?.nurse?.first_name}
                                focused
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Otasining ismi"
                                size="small"
                                variant="outlined"
                                style={{ width: "100%" }}
                                defaultValue={products?.nurse?.patrnomic}
                                focused
                                onChange={(e) => setPatrnomic(e.target.value)}
                              />
                            </div>
                          </>
                        ) : (
                          <h3>
                            {products?.nurse?.last_name}{" "}
                            {products?.nurse?.first_name}{" "}
                            {products?.nurse?.patrnomic}
                            <IconButton
                              onClick={() => setFioPencil(true)}
                              className="ms-2 pt-2"
                            >
                              <Edit className={"text-primary"} />
                            </IconButton>
                          </h3>
                        )}
                      </div>

                      {/* List details */}
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Muassasa:</span>
                        <span className="text-muted">
                          {products?.organization?.title}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Pinfl:</span>
                        <span className="text-muted">
                          {products?.nurse?.pinfl}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Passport seriya va raqami:</span>
                        {passwordPencil ? (
                          <>
                            <div className="d-flex g-1 justify-content-between px-3 py-2">
                              <InputMask
                                value={serial}
                                onChange={(e) => setSerial(e.target.value)}
                                mask="aa"
                                maskChar="_"
                                defaultValue={
                                  products?.nurse?.nurse_document?.serial
                                }
                              >
                                {() => (
                                  <TextField
                                    size="small"
                                    label={"Passport seriya"}
                                    variant="outlined"
                                    fullWidth
                                    focused
                                    className="w-50"
                                  />
                                )}
                              </InputMask>
                              <InputMask
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                mask="9999999"
                                maskChar="_"
                                size="small"
                                defaultValue={
                                  products?.nurse?.nurse_document?.number
                                }
                              >
                                {() => (
                                  <TextField
                                    size="small"
                                    label={"Passport raqam"}
                                    variant="outlined"
                                    fullWidth
                                    className="w-50"
                                    focused
                                  />
                                )}
                              </InputMask>
                            </div>
                          </>
                        ) : (
                          <span className="text-muted">
                            {products?.nurse?.nurse_document?.serial}{" "}
                            {products?.nurse?.nurse_document?.number}
                            <Edit
                              color="primary"
                              className="pt-2"
                              onClick={() => setPasswordPencil(true)}
                            />
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Bola parvarishi ta'tili:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bola_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Oxirgi martda malaka oshirgan sana:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.malaka_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>O'qishni tamomlagan sana:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bitirgan_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Diplom bo'yicha mutahasisligi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.mutaxasislik}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Tamomlagan ta'lim muassasasi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bitirgan}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Lavozimi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.lavozim}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Diplom seriya raqami:</span>
                        {diplomPencil ? (
                          <div>
                            <TextField
                              id="outlined-basic"
                              label="Diplom raqam"
                              size="small"
                              variant="outlined"
                              defaultValue={
                                products?.nurse?.nurse_document?.diplom
                              }
                              focused
                              onChange={(e) => setDiplom(e.target.value)}
                            />
                          </div>
                        ) : (
                          <span className="text-muted">
                            {products?.nurse?.nurse_document?.diplom}
                            <Edit
                              color="primary"
                              className="pt-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => setDiplomPencil(true)}
                            />
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Phone:</span>
                        <span className="text-muted">
                          +{products?.nurse?.phone}
                        </span>
                      </div>

                      {/* <button className="cart">Add to cart</button> */}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <h3>
                          {products?.nurse?.last_name}{" "}
                          {products?.nurse?.first_name}{" "}
                          {products?.nurse?.patrnomic}
                        </h3>
                      </div>

                      {/* List details */}
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Muassasa:</span>
                        <span className="text-muted">
                          {products?.organization?.title}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Pinfl:</span>
                        <span className="text-muted">
                          {products?.nurse?.pinfl}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Passport seriya va raqami:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.serial}{" "}
                          {products?.nurse?.nurse_document?.number}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Bola parvarishi ta'tili:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bola_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Oxirgi martda malaka oshirgan sana:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.malaka_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>O'qishni tamomlagan sana:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bitirgan_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Diplom bo'yicha mutahasisligi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.mutaxasislik}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Tamomlagan ta'lim muassasasi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bitirgan}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Lavozimi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.lavozim}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Diplom seriya raqami:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.diplom}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Phone:</span>
                        <span className="text-muted">
                          +{products?.nurse?.phone}
                        </span>
                      </div>

                      {/* <button className="cart">Add to cart</button> */}
                    </div>
                  )}
                </div>
              ) : role?.role?.roles?.level === 3 ? (
                <div>
                  {products?.status === 0 || products?.status === 1 ? (
                    <div>
                      <div>
                        {fioPencil ? (
                          <>
                            <div className="d-flex g-1 justify-content-between px-3 py-2">
                              <TextField
                                id="outlined-basic"
                                label="Familya"
                                size="small"
                                variant="outlined"
                                style={{ width: "100%" }}
                                defaultValue={products?.nurse?.last_name}
                                focused
                                onChange={(e) => setLastName(e.target.value)}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Ism"
                                size="small"
                                variant="outlined"
                                style={{ width: "100%" }}
                                defaultValue={products?.nurse?.first_name}
                                focused
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Otasining ismi"
                                size="small"
                                variant="outlined"
                                style={{ width: "100%" }}
                                defaultValue={products?.nurse?.patrnomic}
                                focused
                                onChange={(e) => setPatrnomic(e.target.value)}
                              />
                            </div>
                          </>
                        ) : (
                          <h3>
                            {products?.nurse?.last_name}{" "}
                            {products?.nurse?.first_name}{" "}
                            {products?.nurse?.patrnomic}
                            <IconButton
                              onClick={() => setFioPencil(true)}
                              className="ms-2 pt-2"
                            >
                              <Edit className="text-primary" />
                            </IconButton>
                          </h3>
                        )}
                      </div>

                      {/* List details */}
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Muassasa:</span>
                        <span className="text-muted">
                          {products?.organization?.title}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Pinfl:</span>
                        <span className="text-muted">
                          {products?.nurse?.pinfl}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Passport seriya va raqami:</span>
                        {passwordPencil ? (
                          <>
                            <div className="d-flex g-1 justify-content-between px-3 py-2">
                              <InputMask
                                value={serial}
                                onChange={(e) => setSerial(e.target.value)}
                                mask="aa"
                                maskChar="_"
                                defaultValue={
                                  products?.nurse?.nurse_document?.serial
                                }
                              >
                                {() => (
                                  <TextField
                                    size="small"
                                    label={"Passport seriya"}
                                    variant="outlined"
                                    fullWidth
                                    focused
                                    className="w-50"
                                  />
                                )}
                              </InputMask>
                              <InputMask
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                mask="9999999"
                                maskChar="_"
                                size="small"
                                defaultValue={
                                  products?.nurse?.nurse_document?.number
                                }
                              >
                                {() => (
                                  <TextField
                                    size="small"
                                    label={"Passport raqam"}
                                    variant="outlined"
                                    fullWidth
                                    className="w-50"
                                    focused
                                  />
                                )}
                              </InputMask>
                            </div>
                          </>
                        ) : (
                          <span className="text-muted">
                            {products?.nurse?.nurse_document?.serial}{" "}
                            {products?.nurse?.nurse_document?.number}
                            <Edit
                              color="primary"
                              className="pt-2"
                              onClick={() => setPasswordPencil(true)}
                            />
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Bola parvarishi ta'tili:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bola_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Oxirgi martda malaka oshirgan sana:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.malaka_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>O'qishni tamomlagan sana:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bitirgan_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Diplom bo'yicha mutahasisligi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.mutaxasislik}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Tamomlagan ta'lim muassasasi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bitirgan}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Lavozimi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.lavozim}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Diplom seriya raqami:</span>
                        {diplomPencil ? (
                          <div>
                            <TextField
                              id="outlined-basic"
                              label="Diplom raqam"
                              size="small"
                              variant="outlined"
                              defaultValue={
                                products?.nurse?.nurse_document?.diplom
                              }
                              focused
                              onChange={(e) => setDiplom(e.target.value)}
                            />
                          </div>
                        ) : (
                          <span className="text-muted">
                            {products?.nurse?.nurse_document?.diplom}
                            <Edit
                              color="primary"
                              className="pt-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => setDiplomPencil(true)}
                            />
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Phone:</span>
                        <span className="text-muted">
                          +{products?.nurse?.phone}
                        </span>
                      </div>

                      {/* <button className="cart">Add to cart</button> */}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <h3>
                          {products?.nurse?.last_name}{" "}
                          {products?.nurse?.first_name}{" "}
                          {products?.nurse?.patrnomic}
                        </h3>
                      </div>

                      {/* List details */}
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Muassasa:</span>
                        <span className="text-muted">
                          {products?.organization?.title}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Pinfl:</span>
                        <span className="text-muted">
                          {products?.nurse?.pinfl}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Passport seriya va raqami:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.serial}{" "}
                          {products?.nurse?.nurse_document?.number}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Bola parvarishi ta'tili:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bola_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Oxirgi martda malaka oshirgan sana:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.malaka_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>O'qishni tamomlagan sana:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bitirgan_date}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Diplom bo'yicha mutahasisligi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.mutaxasislik}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Tamomlagan ta'lim muassasasi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.bitirgan}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Lavozimi:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.lavozim}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Diplom seriya raqami:</span>
                        <span className="text-muted">
                          {products?.nurse?.nurse_document?.diplom}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                        <span>Phone:</span>
                        <span className="text-muted">
                          +{products?.nurse?.phone}
                        </span>
                      </div>

                      {/* <button className="cart">Add to cart</button> */}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              {fioPencil || passwordPencil || diplomPencil ? (
                <div className="text-end m-3">
                  <Button
                    color="error"
                    onClick={() => {
                      setFioPencil(false);
                      setPasswordPencil(false);
                      setDiplomPencil(false);
                    }}
                  >
                    Bekor qilish
                  </Button>
                  <Button onClick={SaveAfterEdit}>Saqlash</Button>
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      </Card>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Bekor qilish sababi </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            rows={5}
            margin="dense"
            id="name"
            label="Bekor qilish sababini yozin"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Bekor qilish</Button>
          <Button color="error" onClick={handleSubmit}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={sababKor} onClose={() => setSababKor(false)}>
        <DialogTitle>Bekor qilinganligini sababi </DialogTitle>
        <DialogContent>
          <Typography>{products?.comment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setSababKor(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Preview;
