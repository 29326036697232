import { Grid } from "@mui/material";
import React from "react";
import User from "./User";
import "./Chat.css";
import Messages from "./Messages";

export default function Chat() {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <User />
        </Grid>
        <Grid item xs={8}>
          <Messages />
        </Grid>
      </Grid>
    </div>
  );
}
