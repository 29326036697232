import React, { useState } from "react";
import DashboardSidebar from "./DashboardSidebar";
import DashboardNavbar from "./DashboarNavbar";
import { Box, CssBaseline } from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme";

export default function DashboardLoyout({children, roles}) {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const DashboardLayoutRoot = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    paddingTop: 100,
    paddingLeft: 300,
    paddingRight: 20,
  }));

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} open={isSidebarOpen} />
      <DashboardSidebar
        role={roles}
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
      </ThemeProvider>
    </div>
  );
}
