import {Add} from "@mui/icons-material";
import {
    Box,
    Breadcrumbs,
    Button,
    // Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,
    FormControl,
    // FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";
// import MenuTable from "../../Components/Menu/MenuTable";
import MonthTable from "../../Components/Month/MonthTable";
import {api} from "../../utils/api";
import InputMask from "react-input-mask";

export default function Month() {
    const [open, setOpen] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    const [title, setTitle] = useState("");
    const [menuGroups, setMenuGroups] = useState([]);
    const [monthId, setMonthId] = useState();

    const [start_day, setStart_day] = useState();
    const [end_day, setEnd_day] = useState();
    const [receive_from_day, setReceive_from_day] = useState();
    const [receive_to_day, setReceive_to_day] = useState();

    const [res, setRes] = useState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        let body = {
            title:
                monthId === 1 ? "Yanvar" :
                monthId === 2 ? "Fevral" :
                monthId === 3 ? "Mart" :
                monthId === 4 ? "Aprel" :
                monthId === 5 ? "May" :
                monthId === 6 ? "Iyun" :
                monthId === 7 ? "Iyul" :
                monthId === 8 ? "Avgust" :
                monthId === 9 ? "Sentyabr" :
                monthId === 10 ? "Oktyabr" :
                monthId === 11 ? "Noyabr" :
                monthId === 12 ? "Dekabr" :
                "",
            year: title,
            month: monthId,
            is_active: true,
        };

        api
            .post("months/create", body)
            .then((res) => {
                setRes(res.data.data)
                handleClose();
                setOpenSecond(true);
                setTitle("");
                setMonthId("");
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const permissionAdd = () => {
        let body = {
            start_day: start_day,
            end_day: end_day,
            month_id: res?.id,
            receive_from_day: receive_from_day,
            receive_to_day: receive_to_day
        };

        api
            .post("permission_date/store", body)
            .then((res) => {
                console.log(res);
                setOpenSecond(false);
                setStart_day("")
                setEnd_day("");
                setReceive_from_day("");
                setReceive_to_day("");
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                window.location.reload();
            })
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3}}>
                <Link to="/" style={{textDecoration: "none", color: "black"}}>
                    Bosh sahifa
                </Link>
                <Typography>Oylar</Typography>
            </Breadcrumbs>
            <Box
                sx={{
                    mb: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h5" className="fw-bold">
                    Oylar ro'yxati
                </Typography>
                <Button onClick={handleClickOpen} variant="contained">
                    <Add/>
                    Oy qo'shish
                </Button>
            </Box>
            <MonthTable menuGroups={menuGroups}/>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Oy qo'shish</DialogTitle>
                <DialogContent>
                    <FormControl margin="dense" variant="standard" fullWidth>
                        <InputLabel size="small" id="demo-simple-diplom-label">
                            Oylar
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-diplom-label"
                            id="demo-simple-select"
                            onChange={(e) => setMonthId(e.target.value)}
                            label={"Oylar"}
                        >
                            <MenuItem value={1}>Yanvar</MenuItem>
                            <MenuItem value={2}>Fevral</MenuItem>
                            <MenuItem value={3}>Mart</MenuItem>
                            <MenuItem value={4}>Aprel</MenuItem>
                            <MenuItem value={5}>May</MenuItem>
                            <MenuItem value={6}>Iyun</MenuItem>
                            <MenuItem value={7}>Iyul</MenuItem>
                            <MenuItem value={8}>Avgust</MenuItem>
                            <MenuItem value={9}>Sentyabr</MenuItem>
                            <MenuItem value={10}>Oktyabr</MenuItem>
                            <MenuItem value={11}>Noyabr</MenuItem>
                            <MenuItem value={12}>Dekabr</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Yili"
                        onChange={(e) => setTitle(e.target.value)}
                        type="text"
                        fullWidth
                        size="small"
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Bekor qilish
                    </Button>
                    <Button onClick={handleSubmit}>Qo'shish</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSecond} onClose={() => setOpenSecond(false)}>
                <DialogTitle>Date qo'shish</DialogTitle>
                <DialogContent>
                    <InputMask
                        mask={'99.99.9999'}
                        onChange={(e) => setStart_day(e.target.value)}
                    >
                        {() => (
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Boshlanish sanasi"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                            />
                        )}
                    </InputMask>

                    <InputMask
                        mask={'99.99.9999'}
                        onChange={(e) => setEnd_day(e.target.value)}
                    >
                        {() => (
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Tugash sanasi"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                            />
                        )}
                    </InputMask>


                    <FormControl margin="dense" variant="standard" fullWidth>
                        <InputLabel size="small" id="demo-simple-diplom-label">
                            Oy
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-diplom-label"
                            id="demo-simple-select"
                            defaultValue={res?.month}
                            disabled
                            label={"Oy"}
                        >
                            <MenuItem value={1}> Yanvar </MenuItem>
                            <MenuItem value={2}> Fevral </MenuItem>
                            <MenuItem value={3}> Mart </MenuItem>
                            <MenuItem value={4}> Aprel </MenuItem>
                            <MenuItem value={5}> May </MenuItem>
                            <MenuItem value={6}> Iyun </MenuItem>
                            <MenuItem value={7}> Iyul </MenuItem>
                            <MenuItem value={8}> Avgust </MenuItem>
                            <MenuItem value={9}> Sentyabr </MenuItem>
                            <MenuItem value={10}> Oktyabr </MenuItem>
                            <MenuItem value={11}> Noyabr </MenuItem>
                            <MenuItem value={12}> Dekabr </MenuItem>
                        </Select>
                    </FormControl>

                    <InputMask
                        mask={'99.99.9999'}
                        onChange={(e) => setReceive_from_day(e.target.value)}
                    >
                        {() => (
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Qaysi kundan boshlab qabul qilish"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                            />
                        )}
                    </InputMask>

                    <InputMask
                        mask={'99.99.9999'}
                        onChange={(e) => setReceive_to_day(e.target.value)}
                    >
                        {() => (
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Qaysi kuni qabul qilish"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                            />
                        )}
                    </InputMask>


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSecond(false)} color="error">
                        Bekor qilish
                    </Button>
                    <Button onClick={permissionAdd}>Qo'shish</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
