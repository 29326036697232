import { Add } from "@mui/icons-material";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  // FormControl,
  // InputLabel, MenuItem, Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import UsersTable from "../../Components/Users/UsersTable";
import InputMask from "react-input-mask";
import { api } from "../../utils/api";

export default function Users() {
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [role, setRole] = useState([]);

  const [openAlert, setOpenAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [orgId, setOrgId] = useState();
  const [roleId, setRolId] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    api
      .get("organizations")
      .then((res) => {
        setOrganizations(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get("roles")
      .then((res) => {
        setRole(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let body = {
      name: data.get("name"),
      last_name: data.get("last_name"),
      organization_id: orgId,
      phone: Number(phone.replace(/\D/g, "")),
      patrnomic: data.get("patrnomic"),
      role_id: roleId,
    };

    api
      .post("user/create", body)
      .then((res) => {
        setOpen(false);
        setOpenAlert({
          open: true,
          message: res.data.msg,
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setOpenAlert({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  };

  return (
    <div>
      {openAlert.open ? (
        <Snackbar
          open={openAlert.open}
          autoHideDuration={3000}
          onClose={() => setOpenAlert({ ...openAlert, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
        </Snackbar>
      ) : null}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Bosh sahifa
        </Link>
        <Typography>Foydalanuvchilar</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" className="fw-bold">
          Foydalanuvchilar
        </Typography>
        <Button onClick={handleClickOpen} variant="contained">
          <Add />
          Foydalanuvchi qo'shish
        </Button>
      </Box>
      <UsersTable />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Foydalanuvchi qo'shish</DialogTitle>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <DialogContent>
            <Box>
              <TextField
                margin="normal"
                required
                size="small"
                fullWidth
                id="name"
                label="Familya"
                name="last_name"
                autoComplete="last_name"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                size="small"
                fullWidth
                id="name"
                label="Ismi"
                name="name"
                autoComplete="name"
                autoFocus
              />

              <TextField
                margin="normal"
                required
                size="small"
                fullWidth
                id="patrnomic"
                label="Otasining ismi"
                name="patrnomic"
                autoComplete="patrnomic"
                autoFocus
              />

              <InputMask
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                mask="+\9\98(99)999-99-99"
                maskChar="_"
              >
                {() => (
                  <TextField
                    size="small"
                    className="mt-3"
                    label={
                      <span>
                        Telefon raqami <span className="text-danger">*</span>
                      </span>
                    }
                    fullWidth
                  />
                )}
              </InputMask>

              <Box sx={{ mt: 3 }}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-diplom-label">
                    Organizatsiya
                    <span className="text-danger">*</span>
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-diplom-label"
                    id="demo-simple-select"
                    onChange={(e) => setOrgId(e.target.value)}
                    label={
                      <span>
                        Organizatsiya
                        <span className="text-danger">*</span>
                      </span>
                    }
                  >
                    {organizations.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ mt: 3 }}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-diplom-label">
                    Ro'llar
                    <span className="text-danger">*</span>
                  </InputLabel>
                  <Select
                    size="small"
                    onChange={(e) => setRolId(e.target.value)}
                    labelId="demo-simple-diplom-label"
                    id="demo-simple-select"
                    fullWidth
                    label={
                      <span>
                        Ro'llar
                        <span className="text-danger">*</span>
                      </span>
                    }
                  >
                    {role.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Bekor qilish
            </Button>
            <Button type="submit">Qo'shish</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
