import { Add } from "@mui/icons-material";
import {
    Box,
    Breadcrumbs,
    Button,
    // Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,
    FormControl,
    // FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuTable from "../../Components/Menu/MenuTable";
import { api } from "../../utils/api";
import PermissionTable from "../../Components/PermissionDates/PermissionTable";

export default function PermissionDates() {
    const [open, setOpen] = useState(false);
    const [start_day, setStart_day] = useState();
    const [end_day, setEnd_day] = useState();
    const [receive_from_day, setReceive_from_day] = useState();
    const [receive_to_day, setReceive_to_day] = useState();

    const [month_id, setMonth_id] = useState();

    const [months, setMonths] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        api
            .get("months")
            .then((res) => {
                setMonths(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleSubmit = () => {
        let body = {
            start_day: Number(start_day),
            end_day: Number(end_day),
            month_id: Number(month_id),
            receive_from_day: Number(receive_from_day),
            receive_to_day: Number(receive_to_day)
        };
        console.log(body);

        api
            .post("permission_date/store", body)
            .then((res) => {
                console.log(res);
                handleClose();
                setStart_day("")
                setMonth_id("");
                setEnd_day("");
                setReceive_from_day("");
                setReceive_to_day("");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
                <Link to="/" style={{ textDecoration: "none", color: "black" }}>
                    Bosh sahifa
                </Link>
                <Typography>Menu</Typography>
            </Breadcrumbs>
            <Box
                sx={{
                    mb: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h5" className="fw-bold">
                    Permission Dates ro'yxati
                </Typography>
                <Button onClick={handleClickOpen} variant="contained">
                    <Add />
                    Date qo'shish
                </Button>
            </Box>
            <PermissionTable />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Date qo'shish</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Boshlanish sanasi"
                        onChange={(e) => setStart_day(e.target.value)}
                        type="text"
                        fullWidth
                        size="small"
                        variant="standard"
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Tugash sanasi"
                        onChange={(e) => setEnd_day(e.target.value)}
                        type="text"
                        fullWidth
                        size="small"
                        variant="standard"
                    />

                    <FormControl margin="dense" variant="standard" fullWidth>
                        <InputLabel size="small" id="demo-simple-diplom-label">
                            Oy
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-diplom-label"
                            id="demo-simple-select"
                            onChange={(e) => setMonth_id(e.target.value)}
                            label={"Oy"}
                        >
                            {months.map((item) => {
                                return (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Qaysi kundan boshlab qabul qilish"
                        onChange={(e) => setReceive_from_day(e.target.value)}
                        type="text"
                        fullWidth
                        size="small"
                        variant="standard"
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Qaysi kuni qabul qilish"
                        onChange={(e) => setReceive_to_day(e.target.value)}
                        type="text"
                        fullWidth
                        size="small"
                        variant="standard"
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Bekor qilish
                    </Button>
                    <Button onClick={handleSubmit}>Qo'shish</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
