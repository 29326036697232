import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { api } from "../../utils/api";

export default function NewsCreate({ getData }) {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const AddNews = (event) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget);
    let body = {
      title: data.get("title"),
      content: data.get("content"),
    };
    api
      .post("news/create", body)
      .then((res) => {
        getData();
        setOpen(false);
        setOpenAlert({
          open: true,
          message: "Yangilik qo'shildi",
          severity: "success",
        });
      })
      .catch((err) => {
        setOpenAlert({
          open: true,
          message: "Yangilik qo'shishda xatolik",
          severity: "error",
        });
        console.log(err);
      });
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
      >
        <Add />
        Yangilik qo'shish
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box component="form" onSubmit={AddNews} sx={{ mt: 1 }}>
          <DialogTitle>Yangilik Qo'shish</DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              name="title"
            />
            <TextField
              id="outlined-multiline-static"
              label="Content"
              multiline
              rows={5}
              fullWidth
              size="small"
              name="content"
              style={{ marginTop: "15px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="error">
              Bekor qilish
            </Button>
            <Button type="submit">
              Qo'shish
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openAlert.open}
        autoHideDuration={3000}
        onClose={() => setOpenAlert({ ...openAlert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          
          severity={openAlert.severity}
        >
          {openAlert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
