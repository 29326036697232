import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import CategoryTable from "./CategoryTable";
import { api } from "../../utils/api";

export default function CetegoryCrud() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const HandleSubmit = () => {
    let body = {
      title,
      is_active: true,
      type,
    };

    api
      .post("category/add", body)
      .then((res) => {
        console.log(res);
        handleClose();
      })
      .catch((err) => console.log(err))
      .finally(() => window.location.reload())
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Bosh sahifa
        </Link>
        <Typography>Category</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" className="fw-bold">
          Category ro'yxati
        </Typography>
        <Button onClick={handleClickOpen} variant="contained">
          <Add />
          Category qo'shish
        </Button>
      </Box>
      <CategoryTable />

      <Dialog open={open}  onClose={handleClose}>
        <DialogTitle>Category qo'shish</DialogTitle>
        <DialogContent style={{width: '400px'}}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nomi"
            type="text"
            fullWidth
            size="small"
            variant="standard"
            onChange={(e) => setTitle(e.target.value)}
          />

          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth variant="standard">
              <InputLabel size="small" id="demo-simple-diplom-label">
                Turi
              </InputLabel>
              <Select
                size="small"
                onChange={(e) => setType(e.target.value)}
                labelId="demo-simple-diplom-label"
                id="demo-simple-select"
                value={type}
                label={'Turi'}
              >
                <MenuItem value={"malaka"}>Malaka</MenuItem>

                <MenuItem value={"ixtisos"}>Ixtisos</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Bekor qilish
          </Button>
          <Button onClick={HandleSubmit}>Qo'shish</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
