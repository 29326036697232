import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../utils/api";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

export default function MalakaMarkazQR() {
  const [data, setData] = useState([]);
  const { month_id, category_id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [singleOpen, setSingleOpen] = React.useState(false);
  const [bola, setBola] = React.useState(new Date());
  const [index, setIndex] = React.useState();


  useEffect(() => {
    api
      .get(`send_informations/qr_code/nurse/group/${month_id}/${category_id}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [month_id, category_id]);

  const SendInfo = () => {
    let id = data[index]?.NurseGroup.map((item) => item.id);
    let body = {
      id: id,
      issue_date: moment(bola).format("YYYY-MM-DD"),
    };

    api
      .post(`send_informations/info/generate_qr`, body)
      .then((res) => {
        window.open(`http://lara.imedic.uz/${res.data.url}`, '_blank', 'noopener,noreferrer');
        setOpen(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SendSingleInfo = () => {
    let body = {
      id: [index],
      issue_date: moment(bola).format("YYYY-MM-DD"),
    };

    api
      .post(`send_informations/info/generate_qr`, body)
      .then((res) => {
        window.open(`http://lara.imedic.uz/${res.data.url}`, '_blank', 'noopener,noreferrer');
        setSingleOpen(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={SendInfo}>
        {data?.map((item, index) => {
          return (
            <div key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <h5>{item?.group?.name}</h5>
                <Button
                  onClick={() => {
                    setOpen(true);
                    setIndex(index);
                  }}
                  variant="contained"
                >
                  QR Code
                </Button>
              </div>
              <Divider />
              <Card key={index} sx={{ my: 2, borderRadius: "16px" }}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    className="table-bordered"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width={"50px"}>#</TableCell>
                        <TableCell width={"2000px"}>Hamshira F.I.SH</TableCell>
                        <TableCell width={"500px"}>QR Code</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item?.NurseGroup?.map((item) => {
                        return (
                          <>
                            <TableRow
                              key={item.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {item?.id}
                              </TableCell>
                              <TableCell>
                                {item?.nurse?.last_name}{" "}
                                {item?.nurse?.first_name}{" "}
                                {item?.nurse?.patrnomic}
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() => {
                                    setSingleOpen(true)
                                    setIndex(item?.id);
                                  }}
                                >
                                  QR Code
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </div>
          );
        })}
      </form>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Sanani tanlang</DialogTitle>
        <DialogContent>
          <div className="p-3">
            <DesktopDatePicker
              label={`Sanani tanlang`}
              inputFormat="dd/MM/yyyy"
              value={bola}
              onChange={(date) => setBola(date)}
              renderInput={(params) => (
                <TextField size="small" className="w-100" {...params} />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpen(false)}>
            Bekor qilish
          </Button>
          <Button onClick={SendInfo}>Saqlash</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={singleOpen} onClose={() => setSingleOpen(false)}>
        <DialogTitle>Sanani tanlang</DialogTitle>
        <DialogContent>
          <div className="p-3">
            <DesktopDatePicker
              label={`Sanani tanlang`}
              inputFormat="dd/MM/yyyy"
              value={bola}
              onChange={(date) => setBola(date)}
              renderInput={(params) => (
                <TextField size="small" className="w-100" {...params} />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setSingleOpen(false)}>
            Bekor qilish
          </Button>
          <Button onClick={SendSingleInfo}>Saqlash</Button>
        </DialogActions>
      </Dialog>

    </LocalizationProvider>
  );
}
