import React, { useState } from "react";
import { Layout, Container, BoxUpload, ImagePreview } from "./Upload";
import FolderIcon from "../../assets/folder_icon_transparent.png";
import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import { Close, Person } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

function Lavel1() {
  const { id } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [nurse, setNurse] = useState();
  const [openAlert, setOpenAlert] = React.useState({
    open: false,
    message: "",
    severity: "",
  });

  function handleImageChange(e) {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );

      // console.log("filesArray: ", filesArray);

      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
  }

  const DeletePhoto = (photo) => {
    let index = selectedFiles.indexOf(photo);
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
  };

  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <Grid item key={photo} spacing={2}>
          <ImagePreview>
            <div onClick={() => DeletePhoto(photo)} className="close-icon">
              <Close color="error" />{" "}
            </div>
            <img
              id="uploaded-image"
              src={photo}
              draggable={false}
              alt="uploaded-img"
            />
          </ImagePreview>
        </Grid>
      );
    });
  };

  const Create = () => {
    setLoading(true);
    let formData = new FormData();
    let images = document.getElementById("upload-input").files;
    console.log(images, "test");
    Object.values(images).map((img, i) => {
      formData.append("upload_imgs[]", img);
      return true;
    });
    formData.append("send_id", id);
    formData.append("type", "1");
    formData.append("level", "3");

    api
      .post(`send_informations/upload-image/${id}`, formData)
      .then((res) => {
        console.log(res, "res");
        navigate(`/nurse/level4/${id}`);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setOpenAlert({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  };

  useEffect(() => {
    api
      .get(`nurse/send/${id}`)
      .then((res) => setNurse(res.data.data))
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <>
      <div className="mb-3 w-75 mx-auto">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 2,
            justifyContent: "start",
          }}
        >
          <Box>
            <Person style={{ color: "#597897" }} fontSize="large" />
          </Box>
          <Typography
            variant={"h6"}
            className="fw-bold"
            style={{ color: "#597897" }}
          >
            {nurse?.last_name} {nurse?.first_name} {nurse?.patrnomic}
          </Typography>
        </Box>
      </div>
      <Layout>
        <Container>
          <h2>Passport rasimini yuklang</h2>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <BoxUpload>
                <div className="image-upload">
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={FolderIcon}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 100, height: 100 }}
                      />
                      <p style={{ color: "#444" }}>
                        Rasm yuklash uchun bosing!
                      </p>
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      multiple
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                      onChange={handleImageChange}
                    />
                  </>
                </div>
              </BoxUpload>
            </Grid>
            <Grid item xs={6}>
              <Box
                style={{ overflow: "auto", height: "50vh", marginTop: "20px" }}
              >
                <Grid container spacing={3}>
                  {renderPhotos(selectedFiles)}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <LoadingButton
          style={{ width: "20%" }}
          sx={{ mt: 2 }}
          fullWidth
          loading={loading}
          loadingPosition="end"
          variant="contained"
          onClick={Create}
        >
          Jo'natish
        </LoadingButton>
      </Layout>

      <Snackbar
        open={openAlert.open}
        autoHideDuration={3000}
        onClose={() => setOpenAlert({ ...openAlert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
      </Snackbar>
    </>
  );
}

export default Lavel1;
