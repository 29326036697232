import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    TextField,
    Alert,
    FormControlLabel,
    Checkbox, CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { api } from "../../utils/api";

export default function CategoryEdit({ id, getData, open, setOpen }) {
    const [openAlert, setOpenAlert] = useState({
        open: false,
        message: "",
        severity: "",
    });
    const [data, setData] = useState();
    const [checked, setChecked] = useState(true);


    useEffect(() => {
        api.get(`category/${id}`).then((res) => {
            setData(res.data);
        });
    }, [id]);

    const EditCategory = (event) => {
        event.preventDefault();
        let data = new FormData(event.currentTarget);
        let body = {
            title: data.get("title"),
            is_active: checked ? 1 : 0,
            type: data.get("type")
        };

        api
            .put(`category/update/${id}`, body)
            .then((res) => {
                getData()
                setOpen(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    if (!data) {
        return (
            <Dialog open={open} onClose={() => setOpen(false)} >
                <DialogContent  className={'p-5'}>
                    <CircularProgress/>
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <div>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <Box component="form" onSubmit={EditCategory} sx={{ mt: 1 }}>
                    <DialogTitle>Categoryni tahrirlash </DialogTitle>

                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nomi"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            name="title"
                            defaultValue={data?.title}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Url nomi"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            name="type"
                            defaultValue={data?.type}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={data?.is_active}
                                    color="primary"
                                    name="is_active"
                                    onChange={(e) => setChecked(e.target.checked)}
                                />
                            }
                            label="Faol"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="error">
                            Bekor qilish
                        </Button>
                        <Button type="submit">Tahrirlash</Button>
                    </DialogActions>
                </Box>
            </Dialog>
            <Snackbar
                open={openAlert.open}
                autoHideDuration={3000}
                onClose={() => setOpenAlert({ ...openAlert, open: false })}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
            </Snackbar>
        </div>
    );
}