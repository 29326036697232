import React from "react";
import {
  Alert,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ArrowForward } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { api } from "../../utils/api";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Home() {
  const [value, setValue] = React.useState({
    id: true,
  });
  const [body, setBody] = React.useState("");
  const [notFound, setNotFound] = React.useState(false);
  const navigate = useNavigate();
  const [news, setNews] = React.useState();

  const Search = (e) => {
      e.preventDefault()
    let body1 = {
      type: value.id ? "0" : value.pas ? "1" : value.yul ? "2" : "",
      body,
    };
    api
      .post("nurse/search", body1)
      .then((res) => {
        res.data.data
          ? setNotFound(true)
          : navigate(
              `/search/${
                value.id ? "0" : value.pas ? "1" : value.yul ? "2" : ""
              }/${body}`
            );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    api
      .get("news")
      .then((res) => {
        setNews(res.data.data.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      {notFound ? (
        <Snackbar
          autoHideDuration={3000}
          onClose={() => setNotFound(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={notFound}
        >
          <Alert severity="error">Hech nima topilmadi!</Alert>
        </Snackbar>
      ) : null}

      <form onSubmit={Search}>
          <Card
              sx={{
                  borderRadius: "16px",
                  width: "80%",
                  margin: "0 auto",
                  boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
              }}
          >
              <CardContent>
                  <Typography variant="p" className="fw-bold">
                      Qidiruv
                  </Typography>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                  >
                      <FormControlLabel
                          value="id"
                          onChange={(e) => setValue({ id: e.target.checked })}
                          control={<Radio checked={value.id === true ? true : false} />}
                          label="ID raqami bo'yicha izlash"
                      />
                      <FormControlLabel
                          value="pas"
                          onChange={(e) => setValue({ pas: e.target.checked })}
                          control={<Radio />}
                          label="Passport raqami bo'yicha izlash"
                      />
                      <FormControlLabel
                          onChange={(e) => setValue({ yul: e.target.checked })}
                          value="yul"
                          control={<Radio />}
                          label="Yo'lanma raqami bo'yicha izlash"
                      />
                  </RadioGroup>
                  <TextField
                      label={
                          value.id
                              ? "ID raqami bo'yicha izlash"
                              : value.pas
                                  ? "Passport raqami bo'yicha izlash"
                                  : value.yul
                                      ? "Yo'lanma raqami bo'yicha izlash"
                                      : ""
                      }
                      variant="outlined"
                      id="outlined-basic"
                      size="small"
                      fullWidth
                      type={"number"}
                      onChange={(e) => setBody(e.target.value)}
                  />
                  <Box sx={{ textAlign: "end" }}>
                      <Button
                          disabled={body === ""}
                          style={{ width: "20%" }}
                          sx={{ mt: 2 }}
                          fullWidth
                          variant="contained"
                          type={'submit'}
                      >
                          Излаш
                      </Button>
                  </Box>
              </CardContent>
          </Card>
      </form>


      <Card
        sx={{
          borderRadius: "16px",
          p: 2,
          width: "80%",
          margin: "0 auto",
          mt: 3,
        }}
      >
        <CardContent>
          <Typography variant="p" className="fw-bold">
            Янгилик ва эълонлар
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" className="fw-bold">
              {news?.title}
            </Typography>
            <Typography variant="p">{news?.content}</Typography>
          </Box>
          <Box sx={{ textAlign: "end" }}>
            <Link className="text-decoration-none" to={'/news'}>
              <Button
                style={{ width: "20%" }}
                sx={{ mt: 2 }}
                fullWidth
                variant="contained"
              >
                Ko'proq <ArrowForward />
              </Button>
            </Link>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
