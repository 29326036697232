import { Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import NewsEdit from "./NewsEdit";
import {RolesContext} from "../../App";

export default function NewsCard({
  news,
  DeleteNews,
  SnackbarState,
  setSnackbarState,
  getData,
}) {

  const role = useContext(RolesContext)
  return (
    <div>
      <Grid container spacing={2}>
        {news.map((item) => {
          return (
            <Grid key={item.id} item xs={6}>
              <Card sx={{ borderRadius: "16px", p: 2 }}>
                <CardContent>
                  <Box>
                    <Typography variant="h6" className="fw-bold">
                      {item.title}
                    </Typography>
                    <Typography variant="p">{item.content}</Typography>
                  </Box>
                  {
                      role?.role?.roles?.level === 3 ?  null : (
                      <Box sx={{justifyContent: 'end', display: 'flex'}}>
                        <IconButton onClick={() => DeleteNews(item.id)}>
                          <Delete color="error" />

                        </IconButton>
                        <NewsEdit id={item.id} getData={getData}  />
                      </Box>
                      )
                  }

                </CardContent>
              </Card>
            </Grid>
          );
        })}
        <Snackbar
          open={SnackbarState.open}
          autoHideDuration={6000}
          onClose={() => setSnackbarState({ ...SnackbarState, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={SnackbarState.severity}>
            {SnackbarState.message}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}
