import "./bootstrap.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Search from "./pages/Home/Search";
import Users from "./pages/Users/Users";
import Auth from "./pages/Auth/Auth";
import News from "./pages/News/News";
import Menu from "./pages/Menu/Menu";
import Markaz from "./pages/Markaz/Markaz";
import Nurse from "./pages/Nurse/Nurse";
import Preview from "./pages/Nurse/Preview";
import CreateNurse from "./Components/Nurse/Create";
import NotFound from "./pages/NotFound";
import Cookies from "js-cookie";
import Lavel1 from "./pages/UploadImage/Lavel1";
import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import Catagory from "./pages/Catagory/Catagory";
import NurseEdit from "./Components/Nurse/NurseEdit";
import Lavel2 from "./pages/UploadImage/Lavel2";
import Lavel3 from "./pages/UploadImage/Lavel3";
import Lavel4 from "./pages/UploadImage/Lavel4";
import NurseCatagory from "./pages/Send_information/NurseCatagory";
import NursebyMonth from "./pages/Send_information/NursebyMonth";
import EditSendInfo from "./Components/Send_information/EditSendInfo";
import Pdf from "./pages/pdf";
import Category2 from "./pages/Catagory/Category2";
import MalakaMarkaz from "./pages/Malaka-Markaz/MalakaMarkaz";
import IshReja from "./pages/Markaz/IshReja";
import IshrejaItem from "./pages/Markaz/IshRejaItem";
import MalakaMarkazItem from "./pages/Malaka-Markaz/MalakaMarkazItem";
import WebCam from "./pages/WebCam/WebCam";
import MalakaMarkazQR from "./pages/Malaka-Markaz/MalakaMarkazQR";
import IxtisosQabul from "./pages/IxtisosQabul/IxtisosQabul";
import IxtisosQabulItem from "./pages/IxtisosQabul/IxtisosQabulItem";
import IxtisosItem from "./pages/IxtisosQabul/IxtisosItem";
import IxtisosGroup from "./pages/IxtisosGroup/IxtisosGroup";
import IxtisosGroupItem from "./pages/IxtisosGroup/IxtisosGroupItem";
import IxtisosGroupQR from "./pages/IxtisosGroup/IxtisosGroupQR";
import IxtisosPreview from "./pages/Nurse/IxtisosPreview";
import Yullanmalar from "./pages/Yullanmalar/Yullanmalar";
import YullanmalarInside from "./pages/Yullanmalar/YullanmalarInside";
import Taqsimot from "./pages/Yullanmalar/Taqsimot";
import { api } from "./utils/api";
import MenuGroup from "./pages/Menu/MenuGroup";
import DashboardLoyout from "./Components/DashboardLoyout";
import Month from "./pages/Month/Month";
import CategoryCrud from "./pages/Category/Category"
import MuassasaIxtisos from "./pages/IxtisosQabul/MuassasaIxtisos";
import PermissionDates from "./pages/PermissionDates/PermissionDates";
import OldNursePrev from "./pages/Nurse/OldNursePrev";
import AjratilganYul from "./pages/Ajratilgan yullanmalar/AjratilganYul";
import NurseSendInfoTable from "./Components/Send_information/NurseSendInfoTable";
import TicketPermission from "./pages/TicketPermission/TicketPermission";
import Chat from "./pages/Chat/Chat";
import Lavel5 from "./pages/UploadImage/Lavel5";

export const RolesContext = React.createContext();

export default function PersistentDrawerLeft() {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const auth = Cookies.get("token") ? true : false;
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    api
      .get("user")
      .then((res) => {
        setRoles(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [auth]);

  return (
    <div>
      <RolesContext.Provider value={roles}>
        {loginSuccess ? (
          <Snackbar
            autoHideDuration={3000}
            onClose={() => setLoginSuccess(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={loginSuccess}
          >
            <Alert severity="success">Muvaffaqiyatli ro'yxatdan o'tildi</Alert>
          </Snackbar>
        ) : null}

        <BrowserRouter>
          {auth ? (
            <>
              <DashboardLoyout roles={roles}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/search/:type/:body" element={<Search />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/menus" element={<Menu />} />
                  <Route path="/menu/group" element={<MenuGroup />} />
                  <Route path="/month" element={<Month />} />

                  <Route
                    path="/send_informations/:month_id/:type/:status"
                    element={<NurseCatagory />}
                  />
                  <Route
                    path="/send_informations/:month_id/:type/:status/:category_id"
                    element={<NurseSendInfoTable />}
                  />
                  <Route
                    path="/send_informations/:month_id"
                    element={<NursebyMonth />}
                  />

                  {/* MalakaMarkaz */}
                  <Route
                    path="/malaka-markaz/:month_id"
                    element={<MalakaMarkaz />}
                  />
                  <Route
                    path="/malaka/group/:month_id/:category_id"
                    element={<MalakaMarkazItem />}
                  />

                  <Route
                    path="/qr/group/:month_id/:category_id"
                    element={<MalakaMarkazQR />}
                  />

                  {/* ixtisosGroup */}
                  <Route path="/ixtisos/group" element={<IxtisosGroup />} />
                  <Route
                    path="/ixtisos/group/:id"
                    element={<IxtisosGroupItem />}
                  />

                  <Route
                    path="/qr/ixtisos/group/:id"
                    element={<IxtisosGroupQR />}
                  />

                  <Route path="/work/list" element={<IshReja />} />

                  <Route path="/work/item/:id" element={<IshrejaItem />} />

                  <Route path="/ticket/:id" element={<Pdf />} />

                  <Route path="/nurse" element={<Nurse />} />
                  <Route path="/nurse/markaz/:month_id" element={<Markaz />} />

                  <Route path="/nurse/preview/:id/" element={<Preview />} />
                  <Route path="/nurse/preview/:id/:boolean/:nurse_id" element={<OldNursePrev />} />
                  <Route
                    path="/nurse/ixtisos/preview/:id"
                    element={<IxtisosPreview />}
                  />

                  <Route path="/nurse/create" element={<CreateNurse />} />
                  <Route path="/nurse/edit/:id" element={<NurseEdit />} />

                  <Route path="/nurse/catagory/:id" element={<Catagory />} />
                  <Route
                    path="/send_informations/ixtisoslevel/:id/create"
                    element={<Category2 />}
                  />

                  <Route path="/nurse/level3/:id" element={<Lavel1 />} />
                  <Route path="/nurse/level4/:id" element={<Lavel2 />} />
                  <Route path="/nurse/level5/:id" element={<Lavel3 />} />
                  <Route path="/nurse/level6/:id" element={<Lavel4 />} />
                  <Route path="/nurse/level7/:id" element={<Lavel5 />} />
                  <Route
                    path="/nurse/document/:id"
                    element={<EditSendInfo />}
                  />

                  <Route path="/web-cam/:id" element={<WebCam />} />

                  <Route path="/ixtisos/qabul" element={<IxtisosQabul />} />
                  <Route
                    path="/ixtisos/qabul/:id"
                    element={<IxtisosQabulItem />}
                  />
                  <Route
                    path="/ixtisos/item/:category_id/:status"
                    element={<IxtisosItem />}
                  />

                  <Route
                    path="/yullanmalar/:month_id"
                    element={<Yullanmalar />}
                  />
                  <Route
                    path="/yullanmalar/:month_id/:category_id"
                    element={<YullanmalarInside />}
                  />
                  <Route
                    path="/yullanmalar/taqsimoti/:month_id/"
                    element={<Taqsimot />}
                  />

                  <Route path={"/category"} element={<CategoryCrud />} />

                  <Route path={"/ixtisos/xujjatlari"} element={<MuassasaIxtisos />} />

                  <Route path={"/permission_dates"} element={<PermissionDates />} />
                  
                  {/* Ajratilgan yullanmalar */}
                  <Route path={"/ajratilgan_yullanmalar/:id"} element={<AjratilganYul />} />

                  <Route path={"/ticket_permission"} element={<TicketPermission />} />

                  <Route path={"/chats"} element={<Chat />} />


                  <Route path="*" element={<NotFound />} />
                </Routes>
              </DashboardLoyout>
            </>
          ) : (
            <>
              <Routes>
                <Route
                  path="/"
                  element={<Auth setLoginSuccess={setLoginSuccess} />}
                />
              </Routes>
            </>
          )}
        </BrowserRouter>
      </RolesContext.Provider>
    </div>
  );
}
