import React, {useContext, useEffect, useState} from "react";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { api } from "../../utils/api";
import NewsCard from "../../Components/News/NewsCard";
import NewsCreate from "../../Components/News/NewsCreate";
import {RolesContext} from "../../App";
import Pagination from "@mui/material/Pagination";

export default function News() {
  const [news, setNews] = useState([]);
  const [SnackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const role = useContext(RolesContext)
    const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const getData = () => {
    api.get(`news?page=${page}`).then((res) => {
      setNews(res.data.data.data);
      setPageCount(res.data.data.last_page);
    });
  };

  useEffect(() => {
    getData();
  }, [page]);

  const DeleteNews = (id) => {
    api
      .post(`news/${id}/delete`)
      .then((res) => {
        getData();
        setSnackbarState({
          open: true,
          message: "Yangilik Muvaffaqqiyatli o'chirildi",
          severity: "success",
        });
      })
      .catch((err) => {
        setSnackbarState({
          open: true,
          message: "Yangilik o'chirishda xatolik",
          severity: "error",
        });
      });
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Bosh sahifa
        </Link>
        <Typography>Yangilikilar</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" className="fw-bold">
          Yangiliklar
        </Typography>
          {
            role?.role?.roles?.level === 3 ?  null : <NewsCreate getData={getData} />
          }
      </Box>
      <NewsCard
      getData={getData}
        news={news}
        DeleteNews={DeleteNews}
        SnackbarState={SnackbarState}
        setSnackbarState={setSnackbarState}
      />

        <div className={'d-flex justify-content-end mt-3'}>
            <Pagination color="primary" page={page} count={pageCount} onChange={(event, value) => setPage(value)} />
        </div>
    </div>
  );
}
