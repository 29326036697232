import {Add} from "@mui/icons-material";
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
//   DialogContentText,
    DialogTitle,
    FormControlLabel,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import MenuGroupTable from "../../Components/Menu/MenuGroupTable";
// import MenuTable from "../../Components/Menu/MenuTable";
import {api} from "../../utils/api";

export default function MenuGroup() {
    const [open, setOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [title, setTitle] = useState("");
    const [roleId, setRoleId] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        api
            .get("roles")
            .then((res) => {
                setRoles(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleChange = (e) => {
        const {value, checked} = e.target;

        if (checked) {
            setRoleId([...roleId, value]);
        } else {
            setRoleId(roleId.filter((id) => id !== value));
        }
    };

    const handleSubmit = () => {
        let body = {
            title,
            role_id: roleId,
        };

        api
            .post("menu_group/create", body)
            .then((res) => {
                console.log(res);
                handleClose();
                setTitle("");
                setRoleId([]);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                window.location.reload();
            })
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3}}>
                <Link to="/" style={{textDecoration: "none", color: "black"}}>
                    Bosh sahifa
                </Link>
                <Typography>Menu Group</Typography>
            </Breadcrumbs>
            <Box
                sx={{
                    mb: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h5" className="fw-bold">
                    Menyu Grouplar ro'yxati
                </Typography>
                <Button onClick={handleClickOpen} variant="contained">
                    <Add/>
                    Menu Group qo'shish
                </Button>
            </Box>
            <MenuGroupTable roles={roles}/>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Menu Group qo'shish</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nomi"
                        onChange={(e) => setTitle(e.target.value)}
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <div>
                        {roles.map((role) => {
                            return (
                                <FormControlLabel
                                    key={role.id}
                                    control={
                                        <Checkbox
                                            value={role.id}
                                            color="primary"
                                            onChange={handleChange}
                                        />
                                    }
                                    label={role.name}
                                />
                            );
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Bekor qilish
                    </Button>
                    <Button onClick={handleSubmit}>Qo'shish</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
