import { Search } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const users = [
  {
    id: 1,
    name: "John Doe",
    date: "20:20",
  },
  {
    id: 2,
    name: "Alex Murphy",
    date: "9:30",
  },
  {
    id: 3,
    name: "Randy Smith",
    date: "3:30",
  },
  {
    id: 4,
    name: "Derek Smith",
    date: "1:30",
  },
  {
    id: 5,
    name: "Yvonne Smith",
    date: "2:30",
  },
  {
    id: 6,
    name: "Randy Smith",
    date: "3:30",
  },
  {
    id: 7,
    name: "Derek Smith",
    date: "1:30",
  },
  {
    id: 8,
    name: "Yvonne Smith",
    date: "2:30",
  },
];

export default function User() {
  const [activeUser, setActiveUser] = useState(null);

  return (
    <div>
      <Card style={{ height: "85vh", overflowY: "auto" }}>
        <CardContent>
            <FormControl size="small" fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">Qidiruv</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<Search />}
                    label="Amount"
                />
            </FormControl>
          <div>
            {users.map((user) => {
              return (
                <div
                  key={user.id}
                  className={`d-flex align-items-center justify-content-between p-3 ${
                    activeUser === user.id ? "active" : "inactivated"
                  }`}
                  onClick={() => setActiveUser(user.id)}
                >
                  <div className="d-flex align-items-center">
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                    >
                      <Avatar alt={user.name} />
                    </StyledBadge>
                    <p className="ms-2">
                      <strong>{user.name}</strong>
                    </p>
                  </div>
                  <div>
                    <p className="ms-2">
                      <span>{user.date}</span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
