import {
  Button,
  Checkbox, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { api } from "../../utils/api";

export default function MenuGroupEdit({ roles, id, getData, open, setOpen }) {
  const [title, setTitle] = useState("");
  const [roleId, setRoleId] = useState([]);
  const [data, setData] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    api.get(`menu_group/${id}`).then((res) => {
      setData(res.data.data);
      let newData = [...res.data.data.menu_group_role]
      setRoleId(newData.map((item) => item.role_id));
    });
  }, [id]);

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setRoleId([...roleId, value]);
    } else {
      setRoleId(roleId.filter((id) => id !== value));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget);

    let body = {
      title: data.get("title"),
      role_id: roleId,
    };

    api
      .put(`menu_group/update/${id}`, body)
      .then(() => {
        handleClose();
        getData();
        setTitle("");
        setRoleId([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!data) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} >
          <DialogContent  className={'p-5'}>
            <CircularProgress/>
          </DialogContent>
        </Dialog>
    )
  }
  return (
      <div>
        <Dialog open={open} onClose={handleClose}>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <DialogTitle>Menu Groupni tahrirlash</DialogTitle>
            <DialogContent>
              <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Nomi"
                  name="title"
                  type="text"
                  fullWidth
                  variant="standard"
                  defaultValue={data?.title}
              />
              <div>
                {roles.map((role) => {
                  return (
                      <FormControlLabel
                          key={role.id}
                          defaultChecked={true}
                          control={
                            <Checkbox
                                defaultChecked={data?.menu_group_role?.find((id) => id.role_id === role.id) || false}
                                value={role.id}
                                color="primary"
                                onChange={handleChange}
                            />
                          }
                          label={role.name}
                      />
                  );
                })}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Bekor qilish
              </Button>
              <Button  type="submit">Qo'shish</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
  );
}
