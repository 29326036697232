import React from "react";
import {Box, Breadcrumbs, Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';
import {AssignmentInd, Cancel, CheckCircle, FilterAlt, Send} from "@mui/icons-material";
import IxtisosMuassasTable from "../../Components/Ixtisos-Group/IxtisosMuassasTable";


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function MuassasaIxtisos() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [status, setStatus] = React.useState(0)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
                <Link to="/" style={{ textDecoration: "none", color: "black" }}>
                    Bosh sahifa
                </Link>
                <Typography>Ixtisos xujjatlari</Typography>
            </Breadcrumbs>
            <Box
                sx={{
                    mb: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h5" className="fw-bold">
                    Ixtisos Xujjatlari
                </Typography>
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    <FilterAlt />
                    Filter
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => setStatus(0)} disableRipple>
                        <Send />
                        Yuborilgan xujjatlar
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => setStatus(1)} disableRipple>
                        <Cancel />
                        Rad etilgan xujjatlar
                    </MenuItem>
                    <MenuItem onClick={() => setStatus(2)} disableRipple>
                        <CheckCircle />
                        Tasdiqlangan xujjatlar
                    </MenuItem>
                    <MenuItem onClick={() => setStatus(5)} disableRipple>
                        <AssignmentInd />
                        Yollanma Ajratilgan xujjatlar
                    </MenuItem>
                </StyledMenu>
            </Box>

            <IxtisosMuassasTable status={status} />
        </>
    )
}