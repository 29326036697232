import { Person } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import InputMask from "react-input-mask";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// import moment from "moment";
import { api } from "../../utils/api";
import moment from "moment";

export default function NurseEdit() {
  const { register } = useForm();
  const [bola, setBola] = React.useState(new Date());
  const [malaka, setMalaka] = React.useState(new Date());
  const [bitirgan_date, setBitirgan_date] = React.useState(new Date());
  const [phone, setPhone] = React.useState(``);
  const [pinfl, setPinfl] = React.useState(``);
  const [serial, setSerial] = React.useState();
  const [number, setNumber] = React.useState();

  const [data, setData] = React.useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isMount, setIsMount] = React.useState();

  const [AllMutaxasislik, setAllMutaxasislik] = React.useState([]);

  React.useEffect(() => {
    setBola(data?.nurse_document?.bola_date);
    setMalaka(data?.nurse_document?.malaka_date);
    setBitirgan_date(data?.nurse_document?.bitirgan_date);
    setPinfl(`${data?.pinfl}`);
    setPhone(`${data?.phone}`);
    setNumber(data?.nurse_document?.number);
    setSerial(data?.nurse_document?.serial);
  }, [data]);

  React.useEffect(() => {
    setIsMount(true);
    api
      .get("mutaxasisliks")
      .then((res) => {
        setAllMutaxasislik(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get(`nurse/${id}`)
      .then((res) => {
        setData(res.data.data);
        setIsMount(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const Submit = (e) => {
    e.preventDefault();
    let data = new FormData(e.currentTarget);

    let body = {
      pinfl: Number(pinfl.replace(/\D/g, "")),
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      patrnomic: data.get("patrnomic"),
      serial: serial,
      number: number,
      bitirgan_date: moment(bitirgan_date).format("YYYY-MM-DD"),
      mutaxasislik: data.get("mutaxasislik"),
      bitirgan: data.get("bitirgan"),
      lavozim: data.get("lavozim"),
      diplom: data.get("diplom"),
      phone: Number(phone.replace(/\D/g, "")),
      bola_date: moment(bola).format("YYYY-MM-DD"),
      malaka_date: moment(malaka).format("YYYY-MM-DD"),
    };

    api
      .post(`nurse/${id}/update`, body)
      .then(() => {
        setOpenAlert({
          open: true,
          message: "Hamshira tahrirlandi",
          severity: "success",
        });
        navigate("/nurse");
      })
      .catch((err) => {
        setOpenAlert({
          open: true,
          message: "Hamshira tahrirlashda xatolik",
          severity: "error",
        });
        console.log(err)
      });
  };

  if (isMount) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Snackbar
          open={openAlert.open}
          autoHideDuration={3000}
          onClose={() => setOpenAlert({ ...openAlert, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
        </Snackbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ width: "80%", margin: "0 auto" }}
            component="form"
            noValidate
            onSubmit={Submit}
          >
            <Card sx={{ borderRadius: "16px", p: 3 }}>
              {/* Header */}
              <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                <Box>
                  <Person color="primary" fontSize="large" />
                </Box>
                <Typography
                  variant={"h6"}
                  className="fw-bold"
                  color={"primary"}
                >
                  Hamshira tahrirlash
                </Typography>
              </Box>
              <Divider />

              {/* Pinfl */}
              <Box sx={{ mt: 3 }}>
                <InputMask
                  value={pinfl}
                  onChange={(e) => setPinfl(e.target.value)}
                  mask="9999 999 999 9999"
                  maskChar="_"
                  defaultValue={data?.pinfl}
                >
                  {() => (
                    <TextField
                      size="small"
                      focused
                      label={
                        <span>
                          Pinfl <span className="text-danger">*</span>
                        </span>
                      }
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </InputMask>
              </Box>

              {/* Familya va Ism */}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      size="small"
                      // {...register("last_name", { required: true })}
                      id="outlined-basic"
                      defaultValue={data?.last_name}
                      focused
                      name="last_name"
                      label={
                        <span>
                          Familya <span className="text-danger">*</span>
                        </span>
                      }
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      size="small"
                      // {...register("first_name")}
                      id="outlined-basic"
                      defaultValue={data?.first_name}
                      focused
                      name="first_name"
                      multiline
                      label={
                        <span>
                          Ism <span className="text-danger">*</span>
                        </span>
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* Otasining ismi */}
              <Box sx={{ mt: 3 }}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  // {...register("patrnomic")}
                  defaultValue={data?.patrnomic}
                  focused
                  name="patrnomic"
                  label={
                    <span>
                      Otasining ismi <span className="text-danger">*</span>
                    </span>
                  }
                  variant="outlined"
                  fullWidth
                />
              </Box>

              {/* Passport seriya va raqami */}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <Box>
                    <InputMask
                      value={serial}
                      onChange={(e) => setSerial(e.target.value)}
                      mask="aa"
                      maskChar="_"
                      defaultValue={data?.nurse_document?.serial}
                    >
                      {() => (
                        <TextField
                          size="small"
                          label={
                            <span>
                              Passport seriya{" "}
                              <span className="text-danger">*</span>
                            </span>
                          }
                          variant="outlined"
                          fullWidth
                          focused
                        />
                      )}
                    </InputMask>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <InputMask
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      mask="9999999"
                      maskChar="_"
                      size="small"
                      defaultValue={data?.nurse_document?.number}
                    >
                      {() => (
                        <TextField
                          size="small"
                          label={
                            <span>
                              Passport raqam{" "}
                              <span className="text-danger">*</span>
                            </span>
                          }
                          variant="outlined"
                          fullWidth
                          focused
                        />
                      )}
                    </InputMask>
                  </Box>
                </Grid>
              </Grid>

              {/* Bola parvarish va malaka */}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <Box>
                    <DesktopDatePicker
                      label={
                        <span>
                          Ayni vaqtda bola parvarishi ta'tilidami? (qachondan){" "}
                          <span className="text-danger">*</span>
                        </span>
                      }
                      inputFormat="dd/MM/yyyy"
                      value={bola}
                      onChange={(date) => setBola(date)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          className="w-100"
                          focused
                          {...params}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <DesktopDatePicker
                      label={
                        <span>
                          Oxirgi marta malaka oshirgan sanasi{" "}
                          <span className="text-danger">*</span>
                        </span>
                      }
                      size="small"
                      inputFormat="dd/MM/yyyy"
                      value={malaka}
                      onChange={(date) => setMalaka(date)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          focused
                          className="w-100"
                          {...params}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* O'qishni tamomlagan */}
              <Box sx={{ mt: 3 }}>
                <DesktopDatePicker
                  size="small"
                  label={
                    <span>
                      O'qishni tamomlagan oy va yili
                      <span className="text-danger">*</span>
                    </span>
                  }
                  inputFormat="dd/MM/yyyy"
                  value={bitirgan_date}
                  onChange={(date) => setBitirgan_date(date)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      className="w-100"
                      focused
                      {...params}
                    />
                  )}
                />
              </Box>

              {/* Diplom bo'yicha mutahasisligi */}
              <Box sx={{ mt: 3 }}>
                <FormControl fullWidth focused>
                  <InputLabel size="small" id="demo-simple-diplom-label">
                    Diplom bo'yicha mutahasisligi{" "}
                    <span className="text-danger">*</span>
                  </InputLabel>
                  <Select
                    size="small"
                    {...register("mutaxasislik")}
                    labelId="demo-simple-diplom-label"
                    defaultValue={data?.nurse_document?.mutaxasislik}
                    id="demo-simple-select"
                    label={
                      <span>
                        Diplom bo'yicha mutahasisligi{" "}
                        <span className="text-danger">*</span>
                      </span>
                    }
                  >
                    {AllMutaxasislik.map((mutaxasislik) => (
                      <MenuItem key={mutaxasislik.id} value={mutaxasislik.name}>
                        {mutaxasislik.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* muassasa nomi seriya va lavozimi */}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      // {...register("bitirgan")}
                      defaultValue={data?.nurse_document?.bitirgan}
                      focused
                      name="bitirgan"
                      label={
                        <span>
                          Tamomlagan muassasa nomi{" "}
                          <span className="text-danger">*</span>
                        </span>
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      // {...register("lavozim")}
                      defaultValue={data?.nurse_document?.lavozim}
                      focused
                      name="lavozim"
                      label={
                        <span>
                          Lavozimi <span className="text-danger">*</span>
                        </span>
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* Diplom seriya raqam */}
              <Box sx={{ mt: 3 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  // {...register("diplom")}
                  defaultValue={data?.nurse_document?.diplom}
                  focused
                  name="diplom"
                  id="demo-simple-select"
                  label={
                    <span>
                      Diplom seriya raqami{" "}
                      <span className="text-danger">*</span>
                    </span>
                  }
                />
              </Box>

              {/* Telefon raqam */}
              <Box sx={{ mt: 3 }}>
                <InputMask
                  value={phone}
                  defaultValue={data?.phone}
                  onChange={(e) => setPhone(e.target.value)}
                  mask="+\9\98(99)999-99-99"
                  maskChar="_"
                >
                  {() => (
                    <TextField
                      size="small"
                      focused
                      label={
                        <span>
                          Telefon raqami <span className="text-danger">*</span>
                        </span>
                      }
                      fullWidth
                    />
                  )}
                </InputMask>
              </Box>
            </Card>

            <Box sx={{ mt: 3, textAlign: "end" }}>
              <Link to="/nurse" className="text-decoration-none text-dark">
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  sx={{ mr: 2 }}
                >
                  Bekor qilish
                </Button>
              </Link>
              <Button
                type="submit"
                style={{ width: "20%" }}
                variant="contained"
                size="large"
              >
                Saqlash
              </Button>
            </Box>
          </Box>
        </Box>
      </LocalizationProvider>
    );
  }
}
