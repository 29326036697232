import { Person } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import InputMask from "react-input-mask";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { api } from "../../utils/api";
import { LoadingButton } from "@mui/lab";

export default function CreateNurse() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [bola, setBola] = React.useState(null);
  const [malaka, setMalaka] = React.useState(null);
  const [bitirgan_date, setBitirgan_date] = React.useState(null);
  const [phone, setPhone] = React.useState("");
  const [pinfl, setPinfl] = React.useState("");
  const [serial, setSerial] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [diplomNumber, setDiplomNumber] = React.useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [mutaxasislik, setMutaxasislik] = React.useState("");
  const [getPinfl, setGetPinfl] = React.useState();

  const [AllMutaxasislik, setAllMutaxasislik] = React.useState([]);
  const [patrnomic, setPatrnomic] = React.useState("");

  const [openAlert, setOpenAlert] = React.useState({
    open: false,
    message: "",
    severity: "",
  });

  React.useEffect(() => {
    api
      .get("mutaxasisliks")
      .then((res) => {
        setAllMutaxasislik(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    let body = {
      pinfl: pinfl.replace(/\D/g, ""),
      first_name: getPinfl?.fullName?.split(" ")[1],
      last_name: getPinfl?.fullName?.split(" ")[0],
      patrnomic: patrnomic,
      serial: getPinfl?.passSeries,
      number: getPinfl?.passNumber,
      bitirgan_date: moment(bitirgan_date).format("YYYY-MM-DD"),
      mutaxasislik: mutaxasislik,
      bitirgan: data.bitirgan,
      lavozim: data.lavozim,
      diplom: diplomNumber,
      phone: Number(phone.replace(/\D/g, "")),
      bola_date: bola ? moment(bola).format("YYYY-MM-DD") : bola,
      malaka_date: malaka ? moment(malaka).format("YYYY-MM-DD") : malaka,
    };
    api
      .post("nurse/create", body)
      .then(() => {
        setLoading(false);
        navigate("/nurse");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setOpenAlert({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  };

  React.useEffect(() => {
    if (pinfl.replace(/\D/g, "").length === 14) {
      let body = {
        pinfl: Number(pinfl.replace(/\D/g, "")),
      };
      api
        .post("nurses/getByPinfl", body)
        .then((res) => {
          if (res.data.res.passNumber) {
            setGetPinfl(res.data.res);
            if (res.data.res.fullName?.split(" ")[3]) {
              setPatrnomic(
                res.data.res.fullName?.split(" ")[2] +
                  " " +
                  res.data.res.fullName?.split(" ")[3]
              );
            } else {
              setPatrnomic(res.data.res.fullName?.split(" ")[2]);
            }
          } else {
            setOpenAlert({
              open: true,
              message: "Pinfl Notog'ri",
              severity: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setGetPinfl(false);
      reset();
    }
  }, [pinfl]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ width: "80%", margin: "0 auto" }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Card sx={{ borderRadius: "16px", p: 3 }}>
            {/* Header */}
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <Box>
                <Person color="primary" fontSize="large" />
              </Box>
              <Typography variant={"h6"} className="fw-bold" color={"primary"}>
                Hamshira qo'shish
              </Typography>
            </Box>
            <Divider />

            {/* Pinfl */}
            <Box sx={{ mt: 3 }}>
              <InputMask
                value={pinfl}
                onChange={(e) => {
                  setPinfl(e.target.value);
                }}
                mask="9999 999 999 9999"
                maskChar="_"
                required
              >
                {() => (
                  <TextField
                    size="small"
                    required
                    label={"Pinfl"}
                    variant="outlined"
                    fullWidth
                  />
                )}
              </InputMask>
            </Box>
            {/* Familya va Ism */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label={" Familya"}
                    variant="outlined"
                    fullWidth
                    value={getPinfl?.fullName?.split(" ")[0]}
                    focused={getPinfl}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span>This is required</span>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label={
                      <span>
                        Ism <span className="text-danger">*</span>
                      </span>
                    }
                    variant="outlined"
                    fullWidth
                    focused={getPinfl}
                    value={getPinfl?.fullName?.split(" ")[1]}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* Otasining ismi */}
            {getPinfl?.fullName?.split(" ")[2] ? (
              <Box sx={{ mt: 3 }}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label={
                    <span>
                      Otasining ismi <span className="text-danger">*</span>
                    </span>
                  }
                  variant="outlined"
                  fullWidth
                  focused={getPinfl}
                  value={patrnomic}
                />
              </Box>
            ) : (
              <Box sx={{ mt: 3 }}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label={
                    <span>
                      Otasining ismi <span className="text-danger">*</span>
                    </span>
                  }
                  variant="outlined"
                  fullWidth
                />
              </Box>
            )}

            {/* Passport seriya va raqami */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Box>
                  <InputMask
                    onChange={(e) => setSerial(e.target.value)}
                    mask="aa"
                    maskChar="_"
                    value={getPinfl?.passSeries}
                  >
                    {() => (
                      <TextField
                        size="small"
                        label={
                          <span>
                            Passport seriya{" "}
                            <span className="text-danger">*</span>
                          </span>
                        }
                        variant="outlined"
                        fullWidth
                        focused={getPinfl}
                      />
                    )}
                  </InputMask>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <InputMask
                    onChange={(e) => setNumber(e.target.value)}
                    mask="9999999"
                    maskChar="_"
                    size="small"
                    value={getPinfl?.passNumber}
                  >
                    {() => (
                      <TextField
                        size="small"
                        label={
                          <span>
                            Passport raqam{" "}
                            <span className="text-danger">*</span>
                          </span>
                        }
                        variant="outlined"
                        fullWidth
                        focused={getPinfl}
                      />
                    )}
                  </InputMask>
                </Box>
              </Grid>
            </Grid>

            {/* Bola parvarish va malaka */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Box>
                  <DesktopDatePicker
                    label={
                      "Ayni vaqtda bola parvarishi ta'tilidami? (qachondan)"
                    }
                    inputFormat="dd/MM/yyyy"
                    value={bola}
                    onChange={(date) => setBola(date)}
                    renderInput={(params) => (
                      <TextField size="small" className="w-100" {...params} />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <DesktopDatePicker
                    label={"Oxirgi marta malaka oshirgan sanasi"}
                    size="small"
                    inputFormat="dd/MM/yyyy"
                    value={malaka}
                    onChange={(date) => setMalaka(date)}
                    renderInput={(params) => (
                      <TextField size="small" className="w-100" {...params} />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* O'qishni tamomlagan */}
            <Box sx={{ mt: 3 }}>
              <DesktopDatePicker
                size="small"
                label={<span>O'qishni tamomlagan oy va yili</span>}
                inputFormat="dd/MM/yyyy"
                value={bitirgan_date}
                onChange={(date) => setBitirgan_date(date)}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    className="w-100"
                    required
                    {...params}
                  />
                )}
              />
            </Box>

            {/* Diplom bo'yicha mutahasisligi */}
            <Box sx={{ mt: 3 }}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-diplom-label">
                  Diplom bo'yicha mutahasisligi{" "}
                  <span className="text-danger">*</span>
                </InputLabel>
                <Select
                  size="small"
                  onChange={(e) => setMutaxasislik(e.target.value)}
                  labelId="demo-simple-diplom-label"
                  id="demo-simple-select"
                  value={mutaxasislik}
                  label={
                    <span>
                      Diplom bo'yicha mutahasisligi{" "}
                      <span className="text-danger">*</span>
                    </span>
                  }
                >
                  {AllMutaxasislik.map((mutaxasislik, index) => (
                    <MenuItem value={mutaxasislik.name} key={index}>
                      {mutaxasislik.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* muassasa nomi seriya va lavozimi */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    {...register("bitirgan", { required: true })}
                    label={
                      <span>
                        Tamomlagan muassasa nomi{" "}
                        <span className="text-danger">*</span>
                      </span>
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    {...register("lavozim", { required: true })}
                    label={
                      <span>
                        Lavozimi <span className="text-danger">*</span>
                      </span>
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>

            {/* Diplom seriya raqam */}
            <Box sx={{ mt: 3 }}>
              <TextField
                value={diplomNumber}
                onChange={(e) => setDiplomNumber(e.target.value)}
                size="small"
                variant="outlined"
                fullWidth
                id="demo-simple-select"
                label={
                  <span>
                    Diplom seriya raqami <span className="text-danger">*</span>
                  </span>
                }
              />
            </Box>

            {/* Telefon raqam */}
            <Box sx={{ mt: 3 }}>
              <InputMask
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                mask="+\9\98(99)999-99-99"
                maskChar="_"
              >
                {() => (
                  <TextField
                    size="small"
                    label={
                      <span>
                        Telefon raqami <span className="text-danger">*</span>
                      </span>
                    }
                    fullWidth
                  />
                )}
              </InputMask>
            </Box>
          </Card>

          <Box sx={{ mt: 3, textAlign: "end" }}>
            <Link to="/nurse" className="text-decoration-none text-dark">
              <Button
                variant="contained"
                color="error"
                size="large"
                sx={{ mr: 2 }}
              >
                Bekor qilish
              </Button>
            </Link>
            <LoadingButton
              loading={loading}
              style={{ width: "20%" }}
              type="submit"
              variant="contained"
              size="large"
            >
              Saqlash
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={openAlert.open}
        autoHideDuration={3000}
        onClose={() => setOpenAlert({ ...openAlert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
      </Snackbar>
    </LocalizationProvider>
  );
}
