import { Edit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Alert,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { api } from "../../utils/api";
import InputMask from "react-input-mask";

export default function UsersEdit({ id, getData, open, setOpen }) {
  const [openAlert, setOpenAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [data, setData] = useState();
  const [phone, setPhone] = useState("");


  useEffect(() => {
    api.get(`users/${id}`).then((res) => {
      setData(res.data.data);
    });
  }, [id]);

  const EditMenu = (event) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget);
    let body = {
      password: data.get("password"),
      name: data.get("name"),
      phone: Number(phone.replace(/\D/g, "")),
      last_name: data.get("last_name"),
      patrnomic: data.get("patrnomic"),
    };

    console.log(body);
    api
      .post(`user/reset-password`, body)
      .then((res) => {
        getData();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenAlert({
            open: true,
            message: err.response.data.message,
            severity: "error",
        });
      });
  };

  React.useEffect(() => {
    setPhone(`${data?.phone}`);
  }, [data]);

  if (!data) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent className={"p-5"}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <div>
      {openAlert.open ? (
        <Snackbar
          open={openAlert.open}
          autoHideDuration={3000}
          onClose={() => setOpenAlert({ ...openAlert, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
        </Snackbar>
      ) : null}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box component="form" onSubmit={EditMenu} sx={{ mt: 1 }}>
          <DialogTitle>Foydalanuvchi qo'shish</DialogTitle>
          <DialogContent>
            <Box>
              <TextField
                margin="normal"
                required
                size="small"
                fullWidth
                id="name"
                label="Familya"
                name="last_name"
                autoComplete="last_name"
                focused
                defaultValue={data?.last_name}
              />
              <TextField
                margin="normal"
                required
                size="small"
                fullWidth
                id="name"
                label="Ismi"
                name="name"
                autoComplete="name"
                focused
                defaultValue={data?.name}
              />

              <TextField
                margin="normal"
                required
                size="small"
                fullWidth
                id="patrnomic"
                label="Otasining ismi"
                name="patrnomic"
                autoComplete="patrnomic"
                focused
                defaultValue={data?.patrnomic}
              />

              <InputMask
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                mask="+\9\98(99)999-99-99"
                maskChar="_"
                defaultValue={data?.phone}
              >
                {() => (
                  <TextField
                    size="small"
                    className="mt-3"
                    focused
                    label={
                      <span>
                        Telefon raqami <span className="text-danger">*</span>
                      </span>
                    }
                    fullWidth
                  />
                )}
              </InputMask>

              <TextField
                margin="normal"
                required
                size="small"
                fullWidth
                id="password"
                label="Parol"
                name="password"
                autoComplete="password"
                focused
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="error">
              Bekor qilish
            </Button>
            <Button type="submit">Tahrirlash</Button>
          </DialogActions>
        </Box>
      </Dialog>
      {openAlert.open ? (
        <Snackbar
          open={openAlert.open}
          autoHideDuration={3000}
          onClose={() => setOpenAlert({ ...openAlert, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
        </Snackbar>
      ) : null}
    </div>
  );
}
