import {Edit} from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    TextField,
    Alert,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem, CircularProgress,
} from "@mui/material";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import {api} from "../../utils/api";

export default function MenuEdit({id, menuGroups, getData, open, setOpen}) {
    const [openAlert, setOpenAlert] = useState({
        open: false,
        message: "",
        severity: "",
    });
    const [data, setData] = useState();

    useEffect(() => {
        api.get(`menu/${id}`).then((res) => {
            setData(res.data.data);
        });

    }, [id]);

    const EditMenu = (event) => {
        event.preventDefault();
        let data = new FormData(event.currentTarget);
        let body = {
            title: data.get("title"),
            url: data.get("url"),
            parent_id: data.get("parent_id"),
        };

        api
            .post(`menu/${id}/update`, body)
            .then((res) => {
                getData()
                setOpen(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    if (!data) {
        return (
            <Dialog open={open} onClose={() => setOpen(false)} >
                <DialogContent  className={'p-5'}>
                    <CircularProgress/>
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <Box component="form" onSubmit={EditMenu} sx={{mt: 1}}>
                    <DialogTitle>Menyuni tahrirlash</DialogTitle>

                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nomi"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            name="title"
                            defaultValue={data?.title}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Url nomi"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            name="url"
                            defaultValue={data?.url}
                        />

                        <FormControl margin="dense" variant="standard" fullWidth>
                            <InputLabel size="small" id="demo-simple-diplom-label">
                                Menu Grouplar
                            </InputLabel>
                            <Select
                                size="small"
                                labelId="demo-simple-diplom-label"
                                id="demo-simple-select"
                                label={"Menu Grouplar"}
                                name="parent_id"
                                defaultValue={data?.parent_id}
                            >
                                {menuGroups?.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="error">
                            Bekor qilish
                        </Button>
                        <Button type="submit">Tahrirlash</Button>
                    </DialogActions>
                </Box>
            </Dialog>
            {
                openAlert.open ? <Snackbar
                        open={openAlert.open}
                        autoHideDuration={3000}
                        onClose={() => setOpenAlert({...openAlert, open: false})}
                        anchorOrigin={{vertical: "top", horizontal: "right"}}
                    >
                        <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
                    </Snackbar>
                    : null
            }

        </div>
    );

}
