import { Visibility } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../utils/api";

export default function Taqsimot() {
  const { month_id } = useParams();
  const [category, setCategory] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  //   const [response, setResponse] = useState([]);

  useEffect(() => {
    api.get(`yullanmalar/taqsimot/${month_id}`).then((res) => {
      setCategory(res.data.category);
      setOrganizations(res.data.organizations);
    });
  }, [month_id]);

  const Show = (id, index) => {
    let body = {
      month_id,
      organization_id: id,
      categories_id: category.map((item) => {
        return {
          category_id: item.category_id,
        };
      }),
    };

    api
      .post("yullanmalar/taqsimotData", body)
      .then((res) => {
        let newData = [...organizations];
        newData[index].child = res.data.count;
        setOrganizations(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          className="table-bordered"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell width={"50px"}>#</TableCell>
              <TableCell>Yo'nalish nomi</TableCell>
              {category.map((item, index) => {
                return (
                  <Tooltip title={item.categories.title} >
                    <TableCell key={index}>{item.categories.id}</TableCell>
                  </Tooltip>
                );
              })}
              <TableCell>Soni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((item, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell width={"50px"}>{index + 1}</TableCell>
                  <TableCell>{item.organization.title}</TableCell>
                  {item.child
                    ? item.child?.map((kid, index) => {
                        return (
                          <TableCell key={index}>
                            {
                                kid?.response?.response ?
                                `${kid?.response?.response} / ${kid?.sendCount} / ${kid?.confCount}`
                                : ""
                            }
                          </TableCell>
                        );
                      })
                    : category.map((item, index) => {
                        return <TableCell key={index}></TableCell>;
                      })}
                  <TableCell>
                    <IconButton
                      onClick={() => Show(item.organization.id, index)}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
