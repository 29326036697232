import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox, CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { api } from "../../utils/api";

export default function MonthEdit({ id, month, getData, setOpen, open }) {

  const [openAlert, setOpenAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [data, setData] = useState();
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    api.get(`months/${id}`).then((res) => {
      setData(res.data.data);
    });
  }, [id]);

  const EditMenu = (event) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget);
    let body = {
      title:
        data.get("month") === "1"
          ? "Yanvar"
          : data.get("month") === "2"
          ? "Fevral"
          : data.get("month") === "3"
          ? "Mart"
          : data.get("month") === "4"
          ? "Aprel"
          : data.get("month") === "5"
          ? "May"
          : data.get("month") === "6"
          ? "Iyun"
          : data.get("month") === "7"
          ? "Iyul"
          : data.get("month") === "8"
          ? "Avgust"
          : data.get("month") === "9"
          ? "Sentyabr"
          : data.get("month") === "10"
          ? "Oktyabr"
          : data.get("month") === "11"
          ? "Noyabr"
          : data.get("month") === "12"
          ? "Dekabr"
          : "",
      year: data.get("year"),
      month: data.get("month"),
      is_active: checked,
    };

    console.log(body);
    api
      .put(`months/update/${id}`, body)
      .then(() => {
        getData();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!data) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} >
          <DialogContent  className={'p-5'}>
            <CircularProgress/>
          </DialogContent>
        </Dialog>
    )
  }
  return (
    <div>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box component="form" onSubmit={EditMenu} sx={{ mt: 1 }}>
          <DialogTitle>Month Qo'shish</DialogTitle>

          <DialogContent>
            <FormControl margin="dense" variant="standard" fullWidth>
              <InputLabel size="small" id="demo-simple-diplom-label">
                Oylar
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-diplom-label"
                id="demo-simple-select"
                name="month"
                label={"Oylar"}
                defaultValue={data?.month}
              >
                <MenuItem value={1}>Yanvar</MenuItem>
                <MenuItem value={2}>Fevral</MenuItem>
                <MenuItem value={3}>Mart</MenuItem>
                <MenuItem value={4}>Aprel</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>Iyun</MenuItem>
                <MenuItem value={7}>Iyul</MenuItem>
                <MenuItem value={8}>Avgust</MenuItem>
                <MenuItem value={9}>Sentyabr</MenuItem>
                <MenuItem value={10}>Oktyabr</MenuItem>
                <MenuItem value={11}>Noyabr</MenuItem>
                <MenuItem value={12}>Dekabr</MenuItem>
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Yili"
              type="text"
              fullWidth
              size="small"
              name="year"
              variant="standard"
              defaultValue={data?.year}
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={data?.is_active}
                  color="primary"
                  name="is_active"
                  onChange={(e) => setChecked(e.target.checked)}
                />
              }
              label="Faol"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="error">
              Bekor qilish
            </Button>
            <Button type="submit">Tahrirlash</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openAlert.open}
        autoHideDuration={3000}
        onClose={() => setOpenAlert({ ...openAlert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={openAlert.severity}>{openAlert.message}</Alert>
      </Snackbar>
    </div>
  );
}
