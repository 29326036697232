import { Person } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";

export default function Catagory() {
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [nurse, setNurse] = useState()

  useEffect(() => {
    api.get(`/send_informations/level1/${type}`).then((res) => {
      setData(res.data.data);
    });
  }, [type]);

  useEffect(() => {
    api.get(`nurse/${id}`)
    .then((res) => setNurse(res.data.data))
    .catch(err => console.log(err))
  }, [id])
  

  const HandleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    let data = new FormData(e.currentTarget);
    let formData = {
      category_id: data.get("category_id"),
      type,
      nurse_id: id,
      month_id: data.get("month_id"),
    };

    api
      .post(`send_informations/${id}/create`, formData)
      .then((res) => {
        if (res.data.next === "malakaPdf") {
          navigate(`/ticket/${res.data.send_information_id}`);
        }else if(res.data.next === "markazIxtisos"){
          navigate(`/send_informations/ixtisoslevel/${res.data.send_information_id}/create`);
        }
        else {
          navigate(`/nurse/level3/${res.data.send_information_id}`);
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(true);
      })
  };

  return (
    <div>
      <Box component={"form"} onSubmit={HandleSubmit}>
        <Card
          sx={{
            borderRadius: "16px",
            p: 2,
            width: "80%",
            margin: "0 auto",
           
          }}
          className="shadow-lg"
        >
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <Box>
                <Person style={{ color: "#597897" }} fontSize="large" />
              </Box>
              <Typography
                variant={"h6"}
                className="fw-bold"
                style={{ color: "#597897" }}
              >
                {nurse?.last_name} {nurse?.first_name} {nurse?.patrnomic} 
              </Typography>
            </Box>
            <Divider />

            <Box sx={{ my: 3 }}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-diplom-label">
                  Turi
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-diplom-label"
                  id="demo-simple-select"
                  label={`Type`}
                  defaultValue={0}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={0}>Malaka</MenuItem>
                  <MenuItem value={1}>Ixtisos</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ my: 3 }}>
              <FormControl fullWidth required>
                <InputLabel size="small" id="demo-simple-diplom-label">
                  Yo'nalish nomi
                </InputLabel>
                <Select
                  size="small"
                  required
                  labelId="demo-simple-diplom-label"
                  id="demo-simple-select"
                  label={`Yo'nalish nomi`}
                  name="category_id"
                >
                  {data?.category?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            {type === 0 ? (
              <Box sx={{ my: 3 }}>
                <FormControl fullWidth required>
                  <InputLabel size="small" id="demo-simple-diplom-label">
                    Oy
                  </InputLabel>
                  <Select
                    size="small"
                    required
                    labelId="demo-simple-diplom-label"
                    id="demo-simple-select"
                    label={`Oy`}
                    name="month_id"
                  >
                    {data?.month?.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              ""
            )}
          </CardContent>
        </Card>

        <Box sx={{ textAlign: "end", width: "80%", margin: "0 auto", mt: 2 }}>
          <LoadingButton loading={loading} type="submit" variant="contained" sx={{ width: "20%" }}>
            Keyingisi
          </LoadingButton>
        </Box>
      </Box>
    </div>
  );
}
