import * as React from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import { Alert, Grid, Snackbar } from "@mui/material";
import { api } from "../../utils/api";
import Cookies from "js-cookie";
import { LoadingButton } from "@mui/lab";
import "./Auth.css";


export default function Auth({ setLoginSuccess }) {
  const [loginInfoState, setLoginInfoState] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoginInfoState({
      open: false,
      message: "",
      severity: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    let body = {
      id: data.get("id"),
      password: data.get("password"),
    };

    api
      .post("user/login", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          var { token } = res.data.data;
          Cookies.set("token", token);
          Cookies.set(
            "user",
            data.get("id"),
            { expires: 365 },
            { path: "user/login" }
          );
          setLoginSuccess(true);
          setLoading(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        setLoginInfoState({
          severity: "error",
          open: true,
          message: err.response.data.message,
        });
        console.log("Error");
        setLoading(false);
      });
  };

  return (
    <div>
      <section className="background-radial-gradient overflow-hidden">
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div
            className="px-4 py-5 px-md-5 text-center text-lg-start my-5"
            style={{ borderRadius: "16px" }}
          >
            <div className="gx-lg-5 align-items-center mb-5">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <div className="mb-5 mb-lg-0">
                    <div
                      className="card bg-glass position-relative m-auto"
                      style={{ width: "65%", maxHeight: "100%" }}
                    >
                      <div className="card-body px-4 py-5 px-md-5">
                        <div className="d-flex justify-content-center mb-5">
                          <div
                            className="position-absolute text-center"
                            style={{ marginTop: "-100px" }}
                          >
                            <Avatar
                              sx={{
                                width: "100px",
                                height: "100px",
                                backgroundColor: "#5048E5",
                              }}
                            />
                          </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="ID"
                            label={"ID"}
                            name="id"
                            autoComplete="ID"
                            autoFocus
                          />
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={"Password"}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                          />
                          <LoadingButton
                            type="submit"
                            fullWidth
                            loading={loading}
                            variant="contained"
                            sx={{
                              mt: 3,
                              mb: 2,
                              backgroundColor: "#5048E5",
                              p: 2,
                            }}
                          >
                            Sign In
                          </LoadingButton>
                        </form>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Snackbar
          onClose={() =>
            setLoginInfoState({
              ...loginInfoState,
              open: false,
            })
          }
          open={loginInfoState ? loginInfoState.open : false}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity={loginInfoState?.severity}>
            {loginInfoState ? loginInfoState.message : ""}
          </Alert>
        </Snackbar>
      </section>
    </div>
  );
}
