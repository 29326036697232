import React, { useState } from "react";
import {
  Container,
  BoxUpload,
  ImagePreview,
} from "../../pages/UploadImage/Upload2";
import FolderIcon from "../../assets/folder_icon_transparent.png";
import { Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { api } from "../../utils/api";
import { LoadingButton } from "@mui/lab";

function EditLavel2({ data, getData }) {
  const { id } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  function handleImageChange(e) {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );

      // console.log("filesArray: ", filesArray);

      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
  }

  const DeletePhoto = (photo) => {
    let index = selectedFiles.indexOf(photo);
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
  };

  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <Grid item key={photo} spacing={2}>
          <ImagePreview>
            <div onClick={() => DeletePhoto(photo)} className="close-icon">
              <Close color="error" />{" "}
            </div>
            <img
              id="uploaded-image"
              src={photo}
              draggable={false}
              alt="uploaded-img"
            />
          </ImagePreview>
        </Grid>
      );
    });
  };

  const handleDiplom = () => {
    setLoading(true);
    let formData = new FormData();
    let images = document.getElementById("upload-input").files;
    console.log(images, "test");
    Object.values(images).map((img, i) => {
      formData.append("upload_imgs[]", img);
      return true;
    });
    formData.append("send_id", id);
    formData.append("type", "2");
    formData.append("level", "6");

    api.post(`send_informations/upload-image/${id}`, formData).then((res) => {
      console.log(res, "res");
      setLoading(false);
      getData();
      setSelectedFiles([])
    });
  };

  const DownDelete = (item) => {
    console.log(item.id);

    api
      .post(`send_information/photo_delete/${item.id}`)
      .then(() => {
        getData();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Container>
            <h2>Diplom rasimini yuklang</h2>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <BoxUpload>
                  <div className="image-upload">
                    <>
                      <label htmlFor="upload-input">
                        <img
                          src={FolderIcon}
                          draggable={"false"}
                          alt="placeholder"
                          style={{ width: 100, height: 100 }}
                        />
                        <p style={{ color: "#444" }}>Rasm yuklash uchun bosing!</p>
                      </label>

                      <input
                        id="upload-input"
                        type="file"
                        multiple
                        accept=".jpg,.jpeg,.png,"
                        onChange={handleImageChange}
                      />
                    </>
                  </div>
                </BoxUpload>
              </Grid>
              <Grid item xs={6}>
                <Box
                  style={{
                    overflow: "auto",
                    height: "50vh",
                    marginTop: "20px",
                  }}
                >
                  <Grid container spacing={3}>
                    {renderPhotos(selectedFiles)}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={2}>
          <h3>Yuklangan rasmlar</h3>
          <Box style={{ overflowY: "auto", height: "50vh" }}>
            {data?.send_document?.map((item) => {
              return (
                <Box key={item.id}>
                  {item.type === 2 ? (
                    <ImagePreview>
                      <div
                        onClick={() => DownDelete(item)}
                        className="close-icon"
                      >
                        <Close color="error" />{" "}
                      </div>
                      <img
                        id="uploaded-image"
                        src={"http://lara.imedic.uz" + item.url}
                        draggable={false}
                        alt="uploaded-img"
                      />
                    </ImagePreview>
                  ) : (
                    ""
                  )}
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>

      <LoadingButton
        style={{ width: "20%" }}
        sx={{ mt: 2 }}
        fullWidth
        loading={loading}
        loadingPosition="end"
        variant="contained"
        onClick={handleDiplom}
      >
        Saqlash
      </LoadingButton>
    </>
  );
}

export default EditLavel2;
