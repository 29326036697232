import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import NursebyMonthTable from "../../Components/Send_information/NursebyMonthTable";

export default function NursebyMonth() {
  return (
    <div>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" className="fw-bold">
          Nurse By Month
        </Typography>
      </Box>
      <NursebyMonthTable />
    </div>
  );
}
